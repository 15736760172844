import React from 'react';
import { useState, useEffect } from 'react';
import { Container, Row, Col, Image, Figure } from 'react-bootstrap';
import { COLORS, HEADLINEPROPS } from '../constants'
import { Link } from 'react-router-dom';

function Verkaufsprozess() {
    const [isVisible, setIsVisible] = useState(false)
    const [linkText, setLinkText] = useState("Lesen Sie weiter")
	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

	useEffect(() => {
		function handleResize() {
		  setIsSmallScreen(window.innerWidth <= 768);
		}
	
		window.addEventListener('resize', handleResize);
	
		return () => {
		  window.removeEventListener('resize', handleResize);
		};
	  }, []);
	
	let xsValue = HEADLINEPROPS.xsValue
	let colMaxWidth = HEADLINEPROPS.colMaxWidth

	if (isSmallScreen) {
		colMaxWidth = 1000
	} 

	let columnStyle = {maxWidth: String(colMaxWidth) + "rem"}

    useEffect(() => {
        if (isVisible) {
            setLinkText("Weniger anzeigen")
        } else {
            setLinkText("Lesen Sie weiter")
        }
    }, [isVisible]);
    
    const handleClick = () => {
        setIsVisible(!isVisible);
    };

    return (
        <div>
            <Container className="pt-4 pb-4">
				<Row>
					<Col xs={xsValue} style={columnStyle} className="d-flex">
						<Image src={require("../Images/prozesse.png")} fluid className="rounded-image" style={{alignSelf: "flex-end"}}/>
					</Col>
					<Col className="text-start" xs={12 - xsValue} style={{alignSelf: "flex-end"}}>
						<h1 style={{fontFamily: 'LeagueSpartan-SemiBold', color: COLORS.main}}>Der Verkaufsprozess</h1>
						<h5 style={{fontFamily: 'LeagueSpartan-Medium'}}>Von Ronald Wagner</h5>
					</Col>
				</Row>
            </Container>
            <Container className="text-start mt-4 pt-4">            
                <p>Mergers & Acquisitions Transaktionen sind komplexe Prozesse, deren Steuerung Erfahrung und Professionalität erfordert. 
					Eine M&A-Transaktion ist sorgfältig zu planen und zu managen. Nachfolgend ist ein typischer Verkaufsprozess dargestellt.</p>
				
				<Container className="">  
					<Figure style={{paddingLeft: '5vw', paddingRight: '5vw'}}>
						<Figure.Image src={require("../Images/verkaufsprozess.jpg")} fluid>

						</Figure.Image>
						<Figure.Caption style={{textAlign: 'right'}}>
							<p>Beispiel für einen typischen Verkaufsprozess</p>
						</Figure.Caption>
					</Figure>
				</Container>
				
                <h3 style={{fontFamily: 'LeagueSpartan-SemiBold'}} className="pt-4">{"<"}<Link onClick={handleClick}>{linkText}</Link>{">"}</h3>
            </Container>
            {isVisible && 
                <Container className="text-start pt-4">
                    <h3 style={{fontFamily: 'LeagueSpartan-Medium'}}>Entwicklung der Transaktionsstrategie</h3>
                    
                    <p>Leider gehen viele Verkäufer, aber auch Käufer, ohne eine wohlüberlegte Transaktionsstrategie ins Rennen. Was meist dazu führt, 
						dass Ziele nicht erreicht werden oder eine beabsichtigte Transaktion holprig abläuft oder gar scheitert.</p>

                    <p>Eine erfolgreiche Transaktion bedarf eines Mindestmaßes an strategischer Überlegungen und Planungen. Dazu sind die Ziele der 
						gewünschten Transaktion herauszuarbeiten und darauf basierend ein Plan und eine Taktik zu entwickeln.</p>

                    <p>Im Detail siehe: <Link to="/transaktionsstrategie">Transaktionsstrategie</Link></p>
					
					<p>Eine gute Transaktionsstrategie ist mitentscheidend für Erfolg oder Misserfolg einer M&A-Transaktion.</p>

                    <h3 style={{fontFamily: 'LeagueSpartan-Medium'}} className="pt-4">Kurzprofil (Teaser) und Ansprache von potenziellen Interessenten</h3>

                    <p>Zu Beginn wird verkäuferseitig ein Teaser erstellt, dies ist ein anonymisiertes Kurzprofil des zu verkaufenden Unternehmens, 
						welcher potenziellen Käufern „Appetit“ machen soll. Der Teaser wird über M&A-Plattformen publiziert oder es werden damit gezielt 
						potenzielle Interessenten angesprochen.</p>
                    
                    <p className="text-justify">Es kommt auch vor, dass ein Verkaufsprozess käuferseitig angestoßen wird. Dabei werden potenziell geeignete Targets 
						(Unternehmen oder Unternehmensteile) für eine Übernahme gesucht. Das kann z.B. ein Unternehmen sein, bei dem der Eigentümer 
						schon an der Schwelle zum Pensionsalter steht und kein Nachfolger bekannt ist oder ein Unternehmensbereich, der nicht zur 
						Kernkompetenz eines Unternehmens passt und daher Interesse an einem Verkauf bestehen könnte. Hier müssen die relevanten 
						Entscheidungsträger recherchiert werden, um sie ansprechen zu können.</p>

                    <h3 style={{fontFamily: 'LeagueSpartan-Medium'}} className="pt-4">Vertraulichkeitsvereinbarung</h3>

                    <p>Sobald ein Erstkontakt, beispielsweise über einen M&A-Berater, stattgefunden hat und ein beiderseitiges Interesse besteht, 
						wird eine Vertraulichkeitsvereinbarung (Non Disclosure Agreement, NDA) abgeschlossen, damit der Name des Unternehmens 
						und weitere Informationen bekannt gegeben werden können. </p>

                    <h3 style={{fontFamily: 'LeagueSpartan-Medium'}} className="pt-4">Information Memorandum</h3>

                    <p>Beim Information Memorandum handelt es sich um eine attraktive und aussagekräftige Darstellung des Unternehmens. 
						Typischerweise enthält ein Information Memorandum Informationen zu:</p>

                    <ul>
                        <li>Eigentümer, Struktur und Geschichte des Unternehmens</li>
                        <li>Geschäftsmodell</li>
                        <li>Produkte bzw. Dienstleistungen</li>
                        <li>Kunden, Märkte und Marktstellung des Unternehmens </li>
                        <li>Management und Mitarbeiter</li>
                        <li>Finanzdaten wie Jahresabschlüsse, Controlling Daten und eine Finanzplanung</li>
                    </ul>

                    <p>Ein gutes Information Memorandum sollte im Wesentlichen Form, Aufbau und Inhalt eines Businessplans haben und liefert 
						für den Käufer eine wesentliche Grundlage zur Unternehmensbewertung und Kaufpreisermittlung.</p>

                    <p>Siehe dazu auch: <Link to="/unternehmensbewertung">Unternehmensbewertung und Kaufpreisermittlung</Link></p>

					<h3 style={{fontFamily: 'LeagueSpartan-Medium'}} className="pt-4">Indikatives Angebot bzw. Letter of Intent</h3>

					<p>Besteht auf Basis des Information Memorandums und weiterführender Gespräche Interesse der Parteien an einer Transaktion, 
						ist es üblich, dass die Rahmenbedingungen und wesentlichen Konditionen für die beabsichtigte Transaktion inkl. 
						des Kaufpreises und seiner Berechnungsmethode sowie das Procedere in einem Dokument verschriftlicht werden. 
						Das kann ein indikatives Angebot (Non Binding Offer), eine beiderseitige Absichtserklärung (Letter of Intent, LOI) oder ähnliches sein.</p>
					
					<p>Dabei sollte man sich bewusst sein, dass Dinge, die einmal hier festgeschrieben sind, wenngleich in der Regel rechtlich unverbindlich, 
						in der Praxis nur mehr sehr schwer wegzuverhandeln sind. Deshalb ist es ratsam, Prämissen für den Kaufpreis und andere wichtige 
						Rahmenbedingungen zu beschreiben und ggf. auch anzugeben, welche Auswirkung es hat, wenn eine Prämisse nicht zutrifft. 
						Damit hat der Käufer, wenn sich in der Due Diligence herausstellt, dass Annahmen nicht der Realität entsprechen, eine 
						bessere Grundlage zu argumentieren, warum und wie sich das auf den Kaufpreis und ggf. auf andere Vertragsbedingungen auszuwirken hat.</p>
					
					<h3 style={{fontFamily: 'LeagueSpartan-Medium'}} className="pt-4">Due Diligence und Managementgespräche</h3>
				
					<p>Mit dem LOI bzw. Non Binding Offer ist die Basis für die Due Diligence gelegt. Bei der Due Diligence wird das Zielunternehmen 
						geprüft und analysiert, damit sich der Käufer ein umfassendes Bild über den Zustand des Unternehmens machen und 
						seine Annahmen und insbesondere seine Bewertung überprüfen kann.</p>
					
					<p>Siehe dazu: <Link to="/duediligence">Due Diligence</Link></p>

					<p>Im Zuge der Due Diligence sollten auch eine Betriebsbesichtigung durchgeführt und vor allem Management-Gespräche 
						(Gespräche mit Geschäftsführern und Bereichsleitern) geführt werden. Diese Gespräche sind unabdingbar, 
						um ein gutes Verständnis für das Zielunternehmen aufzubauen.</p>
						
					<h3 style={{fontFamily: 'LeagueSpartan-Medium'}} className="pt-4">Vertragsverhandlungen</h3>
				
					<p>Die Erkenntnisse aus der Due Diligence und den Managementgesprächen fließen unmittelbar in die Kaufpreis- und 
						Vertragsverhandlungen ein. In der Regel legt die Verkäuferseite einen Vertragsentwurf vor, der dann 
						meist in mehreren Runden verhandelt wird, bis der Vertragstext endgültig feststeht. Die Vertragsverhandlungen 
						erfordern viel Geschick, Erfahrung und Professionalität. </p>

					<p>Siehe dazu: <Link to="/unternehmenskaufvertrag">Unternehmenskaufverträge und Vertragsverhandlungen</Link></p>

					<h3 style={{fontFamily: 'LeagueSpartan-Medium'}} className="pt-4">Signing</h3>

					<p>Der ausverhandelte Vertrag mit all seinen Anlagen hat nicht selten hundert und mehr Seiten. Bei der Unterzeichnung 
						sind dann häufig Formvorschriften zu beachten. So erfolgt etwa der Verkauf von GmbH-Anteilen in notarieller Form. </p>

					<p>Mit der Unterzeichnung ist der Vertrag rechtsverbindlich, aber noch nicht vollzogen, weil noch die im Vertrag 
						definierten Closing-Bedingungen (z.B. das Einlangen des Kaufpreises auf dem Treuhandkonto) zu erfüllen sind. </p>
					
					<h3 style={{fontFamily: 'LeagueSpartan-Medium'}} className="pt-4">Closing</h3>
					
					<p>Sind alle Closing-Bedingungen erfüllt, tritt das Closing ein, das ist der rechtswirksame Vollzug des Vertrags, womit der 
						Käufer die volle Verfügungsgewalt über das Unternehmen erlangt. Danach sind noch einige Closing-Handlungen (Closing Actions) 
						durchzuführen, beispielsweise die Niederlegung der Geschäftsführung durch den Verkäufer oder die Eintragungen im Firmenbuch.</p>
					
					<p>Idealerweise lassen sich Signing und Closing an einem Tag realisieren. Wenn aber Closing-Bedingungen von Dritten abhängig sind
						 (z.B. die Zustimmung der Wettbewerbsbehörde), ist dies nicht möglich und es kann durchaus ein längerer Zeitraum zwischen 
						 Signing und Closing liegen, womit der Deal noch „in der Luft hängt“.</p>
						
					<h3 style={{fontFamily: 'LeagueSpartan-Medium'}} className="pt-4">Post Merger Integration</h3>

					<p>Ist das Closing eingetreten, beginnt erst die wirklich entscheidende Phase der Transaktion, die über Erfolg oder Misserfolg 
						entscheidet: die Post Merger Integration. Leider erzielen viele Zusammenschlüsse nicht die erhofften Synergien oder scheitern 
						sogar, weil bei der Integration Fehler gemacht werden.</p>
					
					<p>Entscheidend ist, mit der Planung der Integration rechtzeitig zu beginnen, eine gute Kommunikationsstrategie gegenüber den 
						Mitarbeitern und anderen Stakeholdern zu entwickeln und die Integration im Rahmen eines Change Management-Projektes zu steuern.</p>
					<p className="pb-4">Sie dazu: <Link to="/postmerger">Post Merger Integration</Link></p>

                    <Container className="text-start pt-4 mt-4 mein-angebot-container">
                        <h3 style={{fontFamily: 'LeagueSpartan-Medium', color: COLORS.main}}>Mein Angebot</h3>
                        <p>Ich begleite Sie gerne und professionell durch alle Phasen Ihrer Mergers & Acquisitions Transaktion! </p>
                        <p>Übrigens: Zum Einstieg biete ich auch zu verschiedenen <Link to="/workshops">M&A-Fachthemen halbtägige Workshops</Link> zum Fixpreis an. 
							Im Workshop „Verkaufsprozess“ erhalten Sie die wesentlichen Grundlagen zum Verständnis und für den Ablauf 
							von M&A-Transaktionen. Es werden alle Phasen einer M&A-Transaktion behandelt, beginnend von der Entwicklung 
							der Transaktionsstrategie und der Planung über die wesentlichen Dokumente wie Teaser oder Letter of Intent, 
							die Due Diligence und die Vertragsverhandlungen bis zur Unterzeichnung des Vertrags und schließlich der Post Merger Integration.</p>
                        <p>Ich freue mich auf Ihre Anfrage!</p>
                    </Container>
                </Container>
            }
        </div>
    );
}

export default Verkaufsprozess;
