import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomNavbar from './Components/Navbar';
import Footer from './Components/Footer';
import AboutMe from './Components/AboutMe';
import Contact from './Components/Contact';
import MeinBeratungsansatz from './Components/MeinBeratungsansatz';
import Transaktionsstrategie from './Components/Transaktionsstrategie';
import LandingPage from './Components/LandingPage';
import Workshops from './Components/Workshops';
import Impressum from './Components/Impressum';
import MABeratung from './Components/MABeratung';
import Verkaufsprozess from './Components/Verkaufsprozess';
import Unternehmenskaufvertrag from './Components/Unternehmenskaufvertrag';
import PostMergerIntegration from './Components/PostMergerIntegration';
import DueDiligence from './Components/DueDiligence';
import Unternehmensbewertung from './Components/Unternehmensbewertung';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './Components/ScrollToTop';
import { useState, useEffect } from 'react';

function App() {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

  	return (
		<div style={{fontFamily: 'LeagueSpartan-Light'}}>
			<div class="wrapper">
				<CustomNavbar />
				<div className="router-style">
					<Router>
						<ScrollToTop />
						<Routes>
							<Route exact path="/" element={<LandingPage />} />
							<Route exact path="/aboutme" element={<AboutMe />} />
							<Route exact path="/contact" element={<Contact />} />
							<Route path="/beratungsansatz" element={<MeinBeratungsansatz />} />
							<Route path="/transaktionsstrategie" element={<Transaktionsstrategie />} />
							<Route path="/workshops" element={<Workshops />} />
							<Route path="/impressum" element={<Impressum />} />
							<Route path="/maberatung" element={<MABeratung />} />
							<Route path="/verkaufsprozess" element={<Verkaufsprozess />} />
							<Route path="/unternehmenskaufvertrag" element={<Unternehmenskaufvertrag />} />
							<Route path="/postmerger" element={<PostMergerIntegration />} />
							<Route path="/duediligence" element={<DueDiligence />} />
							<Route path="/unternehmensbewertung" element={<Unternehmensbewertung />} />
						</Routes>
					</Router>
				</div>
				<Footer />
			</div>
		</div>
	);
}

export default App;
