import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { COLORS, HEADLINEPROPS } from '../constants'
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

function Unternehmensbewertung() {
	const [isVisible, setIsVisible] = useState(false)
    const [linkText, setLinkText] = useState("Lesen Sie weiter")
	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

	useEffect(() => {
		function handleResize() {
		  setIsSmallScreen(window.innerWidth <= 768);
		}
	
		window.addEventListener('resize', handleResize);
	
		return () => {
		  window.removeEventListener('resize', handleResize);
		};
	  }, []);
	
	let xsValue = HEADLINEPROPS.xsValue
	let colMaxWidth = HEADLINEPROPS.colMaxWidth	
	
	const xsValueFormular1 = isSmallScreen ? 6 : 4;
	const xsValueFormular2 = isSmallScreen ? 8 : 6;

	if (isSmallScreen) {
		colMaxWidth = 1000
	} 

	let columStyle = {maxWidth: String(colMaxWidth) + "rem"}

    useEffect(() => {
        if (isVisible) {
            setLinkText("Weniger anzeigen")
        } else {
            setLinkText("Lesen Sie weiter")
        }
    }, [isVisible]);
    
    const handleClick = () => {
        setIsVisible(!isVisible);
    };

	return (
		<div>
			<Container className="text-start pt-4">
				<Row >
					<Col xs={xsValue} style={columStyle} className="d-flex">
						<Image src={require("../Images/money.jpg")} fluid className="rounded-image" style={{alignSelf: "flex-end"}}/>
					</Col>
					<Col className="text-start" xs={12 - xsValue} style={{alignSelf: "flex-end"}}> 
						<h1 style={{fontFamily: 'LeagueSpartan-SemiBold', color: COLORS.main}}>Unternehmensbewertung und Kaufpreisermittlung</h1>
						<h5 style={{fontFamily: 'LeagueSpartan-Medium'}}>Von Ronald Wagner</h5>
					</Col>
				</Row>
			</Container>
			<Container className="text-start pt-4 mt-4">            
				<p>Es ist schlicht nicht möglich, einen exakten und objektiven Unternehmenswert zu bestimmen. Vielmehr lässt sich nur eine mehr oder weniger große Bandbreite für einen Unternehmenswert ermitteln. Dies sollte man sich bei der Bewertung eines Unternehmens verdeutlichen und die mit der Bewertung verbundenen Unsicherheiten auch transparent machen.</p>
				<p>Zwei Verfahren haben sich in der Unternehmensbewertung etabliert: das DCF- und das Multiplikator-Verfahren.</p>
                <h3 style={{fontFamily: 'LeagueSpartan-SemiBold'}} className="pt-4">{"<"}<Link onClick={handleClick}>{linkText}</Link>{">"}</h3>
			</Container>

			{isVisible && 
                <Container className="text-start pt-4">
					<h3 style={{fontFamily: 'LeagueSpartan-Medium'}} className="pt-4">DCF-Verfahren</h3>

					<p>Beim DCF-Verfahren (Discounted Cash Flow-Verfahren) wird ein Unternehmen als Investitionsobjekt betrachtet, welches dem Eigentümer Überschüsse (Dividenden) zufließen lässt. Die zukünftigen Dividenden werden mit einem Zinssatz auf den Stichtag der Bewertung abgezinst und damit der Barwert ermittelt („diskontiert“). Dieser Zinssatz (Diskontsatz) ist in Abhängigkeit des Risikos und des Marktumfelds zu bestimmen. Das bedeutet: hohes Risiko = hoher Zinssatz, denn ein Investor erwartet für ein höheres Risiko auch eine entsprechend höhere Rendite. </p>					
					
					<p>In einer einfachen Form kann die dem DCF-Verfahren zugrunde liegende Idee am Dividend-Discount Modell (DDM) erklärt werden. Dabei wird eine erwartete zukünftige („ewige“) Dividende, welche mit einer Wachstumsrate (g) wächst, mit dem Zinssatz (r) diskontiert und man erhält so den Unternehmenswert.</p>
					
					<Row className="d-flex align-items-baseline pt-4 pb-4 mb-4 mt-4">
						<Col></Col>
						<Col xs={12} style={{maxWidth: "24rem"}}>
							<Image src={require("../Images/equ_1.png")} fluid className="rounded-image"/>
						</Col>
						<Col></Col>
					</Row>

					<p>Die Probleme beim DCF-Verfahren bestehen darin:</p>
					
					<ul>
                        <li>zukünftige Überschüsse (Dividenden) zu prognostizieren und</li>
						<li>einen risikoadäquaten Zinssatz festzulegen.</li>
                    </ul>

					<p>Leider werden diese Unsicherheiten von Bewertern und Analysten selten ausreichend klar offengelegt. Eine Möglichkeit zum besseren Verständnis der Unsicherheiten besteht darin, mehrere Szenarien zu berechnen und damit eine Bandbreite für den Unternehmenswert anzugeben.</p>
					
					<p>Das DCF-Verfahren wird von Wirtschaftsprüfen bei der Unternehmensbewertung angewendet, z.B. zur Wertbestimmung im Rahmen eines Squeeze-outs oder bei Unternehmenswertgutachten für steuerliche Zwecke. In den entsprechenden Bewertungsstandards der Wirtschaftsprüfer (IDW S 1 für Deutschland und KFS/BW 1 für Österreich) ist es für die Bewertung – gemeinsam mit dem Ertragswertverfahren, das auf den gleichen Überlegungen wie das DCF-Verfahrens beruht – vorgegeben.</p>
					
					<h3 style={{fontFamily: 'LeagueSpartan-Medium'}} className="pt-4">Multiplikatorverfahren</h3>

					<p>In der M&A-Praxis wir hauptsächlich das Multiplikatorverfahren angewendet. Nach diesem Konzept werden dem Zielunternehmen (das Unternehmen, das zu bewerten ist) vergleichbare Unternehmen identifiziert, danach wird mit einer Kennzahl (z.B. EBIT) ein Multiplikator berechnet und damit der Wert des Zielunternehmens ermittelt. </p>

					<Row className="d-flex align-items-baseline pt-4 pb-4 mb-4 mt-4">
						<Col></Col>
						<Col xs={12} style={{maxWidth: "34rem"}}>
							<Image src={require("../Images/equ_2.png")} fluid className="rounded-image"/>
						</Col>
						<Col></Col>
					</Row>

					<p>Es ist der wesentliche Vorteil des Multiplikatorverfahrens, dass Marktwerte verwendet und damit Einschätzungen des Marktes hinsichtlich Marktrends und Geschäftsrisiken berücksichtigt werden. Das macht es gut verständlich.</p>

					<p>Die Probleme beim Multiplikatorverfahren sind:</p>

					<ul>
						<ol>
							<li>vergleichbare Unternehmen zu finden, deren Preise auch bekannt sind</li>
							<li>die Kennzahl (z.B. EBIT) entsprechend zu adjustieren, damit nicht Äpfel mit Birnen verglichen werden.</li>
						</ol>
                    </ul>

					<p>{"Ad. 1)"} Wird nur eine grobe Einschätzung eines Unternehmenswerts benötigt, lässt sich auf die von diversen Informationsanbietern angebotenen Multiplikatoren zurückgreifen, die für verschiedene Branchen und Unternehmensgrößen angeboten werden. Das Problem dabei ist leider, dass wenig über die Qualität und die Ableitung dieser Multiplikatoren bekannt ist. Auch ist fraglich, inwieweit das Zielunternehmen mit den „Branchenunternehmen“ lt. Informationsanbieter vergleichbar ist, z.B. weil es sich um einen Mischbetrieb handelt oder das Geschäftsmodell von anderen Unternehmen der Branche abweicht.</p>
					
					<p>Will man eine fundierte Multiplikatorbewertung erstellen, kommt man um die Bildung einer Peer Group aus Vergleichsunternehmen nicht herum. Man versucht ca. 5 – 10 Vergleichsunternehmen zu finden, die von der Branche, der Unternehmensgröße und dem Geschäftsmodell vergleichbar sind und aus diesen Vergleichsunternehmen mittels Durchschnitts- oder besser Medianbildung einen Multiplikator abzuleiten. Es ist teils sehr schwierig, genügend geeignete Vergleichsunternehmen zu finden, für die auch die erforderlichen Daten verfügbar sind. Ein Lösungsansatz besteht darin, dass man die für die einzelnen Unternehmen errechneten Multiplikatoren mit Begleitvariablen (z.B. der Wachstumsrate) modifiziert oder den Multiplikator mittels Regressionsanalyse ableitet und damit Unternehmen besser vergleichbar macht.</p>
					
					<p>{"Ad. 2)"} Das ist in der Praxis ein sehr relevanter Punkt. Das Problem besteht darin, dass die Kenngröße für den Multiplikator (z.B. EBIT) des Zielunternehmens häufig verzerrt ist durch:</p>
					
					<ul>
                        <li>periodenfremde Erträge oder Aufwendungen;</li>
						<li>einen „Investitionsrückstau“ im Zielunternehmen. Gerade bei Unternehmen, die stark gewachsen sind, haben oft die Strukturen für Personalwesen, Controlling oder IT-Security nicht mitgehalten. Das EBIT ist dann um entsprechende Kosten zu reduzieren;</li>
						<li>wenn der Eigentümer das Unternehmen selbst führt, ist auch zu hinterfragen, ob ein angemessener Unternehmerlohn gezahlt wurde – also der Lohn, den ein Fremdgeschäftsführer erhalten würde.</li>
					</ul>

					<p>Dies alles erfordert eine sorgfältige Analyse des Zielunternehmens.</p>

					<h3 style={{fontFamily: 'LeagueSpartan-Medium'}} className="pt-4">Businessplan als Grundlage für die Bewertung</h3>

					<p>Die Analyse des Zielunternehmens kann am besten mit einem Businessplan erfolgen, weil damit das für die Bewertung und die nachfolgenden Preisverhandlungen erforderliche Verständnis des Zielunternehmens gut aufgebaut werden kann. Bei einem gut ausgearbeiteten Business Plan handelt es sich nicht nur um ein simples Excel-Sheet mit einer Planrechnung für die nächsten Jahre. Vielmehr ist ein Businessplan ein betriebswirtschaftliches Konzept, welches eine Realisierungsstrategie zur Umsetzung des Geschäftsmodells beinhaltet. Er umfasst einen Zeitraum von etwa 3 – 5 Jahren. Wesentliche Elemente eines Businessplans sind: </p>
					
					<ul>
                        <li>Unternehmensbeschreibung, Geschäftsmodell und Unternehmensziele</li>
						<li>Produkte bzw. Dienstleistungen</li>
						<li>Kooperationspartner, Lieferanten, Beschaffung und Produktion</li>
						<li>Marketing und Vertrieb</li>
						<li>Management</li>
						<li>Mitarbeiter</li>
						<li>Finanzplan</li>
					</ul>

					<p>Der Finanzplan ist die wesentliche Grundlage für die Bewertung, in ihm verdichtet sich der gesamte Businessplan in Zahlen.</p>
					
					<p>Auch beim Multiplikatorverfahren ist der Businessplan sehr hilfreich bei der Analyse des Zielunternehmens und der Vergleichsunternehmen. Er kann auch dazu genutzt werden, die Multiplikatorbewertung stärker zukunftsorientiert auszugestalten. Und natürlich kann der Businessplan dann auch gleich als Grundlage für eine ergänzende DCF-Bewertung herangezogen werden.</p>
					
					<h3 style={{fontFamily: 'LeagueSpartan-Medium'}} className="pt-4">Preisfindung</h3>

					<p>Ausgangspunkt für die Preisverhandlungen sollte ein solider Businessplan sein, das gilt gerade auch bei Anwendung des Multiplikatorverfahrens, weil sich die bewertungsrelevanten Argumente im Businessplan verdichten. Er ist damit die wesentliche Grundlage des Bewertungshandels zwischen den Verhandlungspartnern. Auf dieser Grundlage können die Partner bei den Preisverhandlungen ihre Argumente austauschen und untermauern, warum sie eine Annahme für realistisch und eine andere für weniger realistisch halten und wie solche Annahmen einzupreisen sind.</p>
					<p>Häufig einigen sich die Verhandlungspartner schon relativ früh im Verkaufsprozess auf einen Multiplikator und dessen Höhe. Eine große Rolle spielen dann im Zuge des weiteren Bewertungshandels Adjustierungen der dem vereinbarten Multiplikator zugrundeliegenden Kennzahl, wie z.B. dem EBIT. Die Verhandlungspartner argumentieren, warum die Höhe des EBITs realistisch ist oder auch nicht, wo Bereinigungen und Adjustierungen erforderlich sind usw. Im Zuge der Verhandlungen werden dann auch häufig einvernehmliche Anpassungen des Businessplans vorgenommen, womit eine Grundlage für die Preiseinigung geschaffen wird. Diese Preisverhandlungen sind oft zäh, aber der Businessplan liefert eine gute Basis und Struktur, um sie erfolgreich zu meistern.</p>
					<p className="pb-4">Der Kaufpreis wird üblicherweise „Cash and Debt Free“ vereinbart. Cash and Debt Free bedeutet, dass die übernommenen Schulden (Finanzverbindlichkeiten und Quasi-Finanzverbindlichkeiten) des Unternehmens vom Kaufpreis abgezogen werden und die im Unternehmen vorhandene Überliquidität (also jener Cash inkl. Cash Equivalents, der über der betriebsnotwendigen Liquidität liegt) hinzugerechnet wird. Gegenstand der Preisverhandlungen ist daher auch, was genau als Cash und was als Debt zu werten und wie hoch die betriebsnotwendige Liquidität ist. Die betriebsnotwendige Liquidität wird teils als Faustformel mit etwa 2–3% des Umsatzes angegeben, was natürlich ziemlich ungenau ist. Besser ist es, die erforderliche Liquidität anhand einer Cash-Flow-Rechnung der letzten 12 Monate auf Monatsbasis zu ermitteln. Der Monat mit dem höchsten Liquiditätsbedarf zzgl. eines Sicherheitszuschlages von etwa 20–30% kann als Referenz herangezogen werden.</p>
					<Container className="text-start pt-4 mt-4 mein-angebot-container">
                        <h3 style={{fontFamily: 'LeagueSpartan-Medium', color: COLORS.main}}>Mein Angebot</h3>
                        <p>Gerne unterstütze ich Sie mit meinem profunden Know-how in der Unternehmensbewertung, damit Sie eine solide Grundlage für die Abschätzung eines möglichen Kaufpreises haben. Dazu gehört auch die Ausarbeitung von Argumenten und einer Argumentationsstrategie für den gewünschten Kaufpreis, um in den Kaufpreisverhandlungen ein optimales Ergebnis erzielen zu können.</p>
                        <p>Übrigens: Zum Einstieg biete ich auch zu verschiedenen <Link to="/workshops">M&A-Fachthemen halbtägige Workshops</Link> zum Fixpreis an. Im Workshop „Unternehmensbewertung und Kaufpreisermittlung“ erhalten Sie die wesentlichen Grundlagen für die Bewertung und Kaufpreisabschätzung. Dieses Bewertungs-Know-how ist die Grundlage für die Kaufpreisverhandlungen. Auf dieser Basis können Sie argumentieren, weshalb ein Kaufpreis zu hoch bzw. zu nieder ist, diese Argumente mit bewertungsrelevanten Fakten untermauern und somit die Höhe des Kaufpreises erfolgreicher verhandeln.</p>
						<p>Ich freue mich auf Ihre Anfrage!</p>
					</Container>
				</Container>}
		</div>
		);
}

export default Unternehmensbewertung;
