import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useState, useEffect } from 'react';

const CustomNavbar = () => {
	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);
	
	useEffect(() => {
		function handleResize() {
		  setIsSmallScreen(window.innerWidth <= 768);
		}
	
		window.addEventListener('resize', handleResize);
	
		return () => {
		  window.removeEventListener('resize', handleResize);
		};
	  }, []);
	
	let logoHeight = 50

	if (isSmallScreen) {
		logoHeight = 30
	} 

	return (
		<Navbar collapseOnSelect expand="lg" bg="white" variant="light" style={{borderColor: "#f89e25", borderBottom: "2px solid #f89e25"}}>
		<Container>
			<Navbar.Brand href="/">
				<img
					src={require('../Images/Logo.png')}
					height={logoHeight}
					className="d-inline-block align-top"
					alt="Logo"
				/>
			</Navbar.Brand>
			<Navbar.Toggle aria-controls="responsive-navbar-nav" className="ms-auto"/>
			<Navbar.Collapse id="responsive-navbar-nav">
				<Nav className="justify-content-end ms-auto d-flex align-items-center mt-3">
					<h4><Nav.Link href="/aboutme" className="footer-link me-4 pe-4">Über mich </Nav.Link></h4>
					<h4><Nav.Link href="/contact" className="footer-link me-4 pe-4">Kontakt </Nav.Link></h4>
				</Nav>
			</Navbar.Collapse>
		</Container>
		</Navbar>
	);
};

export default CustomNavbar;