import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { COLORS } from '../constants'
import { Link } from 'react-router-dom';

function Workshops() {
return (
    <div>
        <Container className="text-start pt-4">
            <h1 style={{fontFamily: 'LeagueSpartan-SemiBold', color: COLORS.main}}>Mergers & Acquisitions-Workshops</h1>
        </Container>
        <Container className="text-start pt-4 mt-4">            
            <p>Ihnen und Ihren Mitarbeitern fehlt es an Erfahrung bei Unternehmens(ver)käufen oder Sie wollen Ihr M&A-Know-how vertiefen?<br></br>
				Ich biete zu 6 Kernthemen aus Mergers & Acquisitions jeweils halbtägige Workshops an. Diese beinhalten ein Vorgespräch,
				den halbtägigen Workshop bei Ihnen vor Ort, Unterlagen und eine Zusammenfassung für Sie. Ich biete diese Workshops zum Fixpreis von jeweils EUR 900 (Preis für 2023) an.</p>
			<h2 style={{fontFamily: 'LeagueSpartan-Medium'}} className="mb-4 pb-4 mt-4 pt-4">Die Workshops zu den 6 Kernthemen von Mergers & Acquisitions:</h2>

			<h3 style={{fontFamily: 'LeagueSpartan-Medium', color: COLORS.dark}}>Verkaufsprozess</h3>

			<p>Mergers & Acquisitions-Transaktionen sind komplexe Prozesse, deren Steuerung Erfahrung und Professionalität erfordert. Gute Planung und 
				sorgfältiges Management ist die Grundlage für einen erfolgreichen Abschluss einer Transaktion.</p>
				
			<p>Im Workshop „Verkaufsprozess“ erhalten Sie die wesentlichen Grundlagen zum Verständnis und für den Ablauf von M&A-Transaktionen. 
				Es werden alle Phasen einer M&A-Transaktion behandelt, beginnend von der Entwicklung der Transaktionsstrategie und der Planung über die 
				wesentlichen Dokumente wie Teaser oder Letter of Intent, die Due Diligence und die Vertragsverhandlungen bis zur Unterzeichnung des Vertrags 
				und schließlich der Post Merger Integration. </p>

			<h3 style={{fontFamily: 'LeagueSpartan-Medium', color: COLORS.dark}} className="pt-4">Transaktionsstrategie</h3>

			<p>Leider gehen viele Verkäufer, aber auch Käufer, ohne eine wohlüberlegte Transaktionsstrategie in das Rennen. Was meist dazu führt, 
				dass Ziele nicht erreicht werden oder eine beabsichtigte Transaktion holprig abläuft oder gar scheitert. </p>
			
			<p>Im Workshop „Transaktionsstrategie“ erhalten Sie die wesentlichen Grundlagen zum Verständnis und für die Entwicklung einer 
				Transaktionsstrategie. Dabei werden nicht nur die drei Elemente der Transaktionsstrategie – die Ziele, der Plan und die Taktik – 
				behandelt, sondern auch, wie eine konkrete Transaktionsstrategie definiert werden kann und wie die Inhalte einer Transaktionsstrategie aussehen.</p>

			<h3 style={{fontFamily: 'LeagueSpartan-Medium', color: COLORS.dark}} className="pt-4">Unternehmensbewertung und Kaufpreisermittlung</h3>

			<p>Es ist zwar nicht möglich, einen exakten, objektiven Unternehmenswert zu berechnen, weil Unternehmen komplexe Gebilde darstellen. 
				Mit den modernen Bewertungsverfahren (DCF- und Multiplikatorverfahren) stehen aber fundierte Methoden zur Unternehmenswertermittlung zur Verfügung. 
				In beiden Fällen bietet ein solider Businessplan die Grundlage für die Wertermittlung und die nachfolgende Preisfindung in den Verhandlungen.</p>
			
			<p>Im Workshop „Unternehmensbewertung und Kaufpreisermittlung“ erhalten Sie die wesentlichen Grundlagen für die Bewertung und Kaufpreisabschätzung. 
				Dieses Bewertungs-Know-how ist die Grundlage für die Kaufpreisverhandlungen. Auf dieser Basis können Sie argumentieren, weshalb ein Kaufpreis 
				zu hoch bzw. zu nieder ist, diese Argumente mit bewertungsrelevanten Fakten untermauern und somit die Höhe des Kaufpreises erfolgreicher verhandeln.</p>

			<h3 style={{fontFamily: 'LeagueSpartan-Medium', color: COLORS.dark}} className="pt-4">Due Diligence</h3>
			<p>Bei der Due Diligence wird das Zielunternehmen geprüft und analysiert, damit sich der Käufer ein umfassendes Bild über den Zustand des Zielunternehmens 
				machen kann. Auch hilft die Due Diligence dabei, das Geschäftsmodell des Zielunternehmens besser zu verstehen und mögliche Synergien zu erkennen und 
				zu bewerten. Die Due Diligence bildet damit eine wesentliche Grundlage für die Vertragsverhandlungen und die Preisfestsetzung.</p>
			<p>Im Workshop „Due Diligence“ erhalten Sie die wesentlichen Grundlagen zum Verständnis und zum Ablauf einer Due Diligence. Verkäufer können 
				sich damit optimal vorbereiten und die erforderlichen Unterlagen im Vorfeld zeitgerecht aufbereiten. Käufer und ihre teilnehmenden Mitarbeiter 
				können sich vorbereiten, um sich in der Due Diligence richtig zu verhalten und dort die relevanten Schwerpunkte zu setzen. Die Due Diligence 
				kann damit effektiv genutzt werden, um das Zielunternehmen zu verstehen und die Erkenntnisse im weiteren Verkaufsprozess und besonders in den 
				Preisverhandlungen zu verwerten.</p>

			<h3 style={{fontFamily: 'LeagueSpartan-Medium', color: COLORS.dark}} className="pt-4">Unternehmenskaufverträge und Vertragsverhandlungen</h3>
			<p>Unternehmenskaufverträge sind schon alleine vom Umfang her komplexe rechtlich-wirtschaftliche Gebilde, deren Gestaltung und Verhandlung entsprechende 
				Expertise benötigt. Auch wenn Käufer und Verkäufer in den Verhandlungen von Experten wie spezialisierten Juristen und M&A-Beratern unterstützt werden, 
				ist ein gewisses Basis-Know-how unerlässlich. </p>
			<p>Im Workshop „Unternehmenskaufverträge und Vertragsverhandlungen“ erhalten Sie das Basis Know-how zu Unternehmenskaufverträgen und zu den 
				Vertragsverhandlungen sowie Einblick in den Ablauf von Verhandlungen und was es dabei zu beachten gilt.</p>

			<h3 style={{fontFamily: 'LeagueSpartan-Medium', color: COLORS.dark}} className="pt-4">Post Merger Integration</h3>
			<p>Bei Mergers & Acquisitions hat die Post Merger-Phase die weitaus größte Bedeutung für das erfolgreiche Gelingen der Transaktion. Viele 
				Zusammenschlüsse erzielen nicht die erhofften Synergien oder scheitern sogar. Wesentlich ist, die Integration sorgfältig zu planen und 
				professionell durchzuführen, als sie eher nur „passieren“ zu lassen.</p>
			<p>Im Workshop „Post Merger Integration“ erhalten Sie einen soliden Überblick über das Thema Post Merger Integration, die Problemstellungen 
				und Lösungsansätze und Best Practice-Beispiele für eine erfolgreiche Integration.</p>

		</Container>
    </div>
    );
}

export default Workshops;
