import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { COLORS } from '../constants'

function Contact() {
	function decode(a) {
		return a.replace(/[a-zA-Z]/g, function(c) {
			return String.fromCharCode((c <= "Z" ? 90 : 122) >= (c = c.charCodeAt(0) + 13) ? c : c - 26);
		})
	}

	function openMailer() {
		var y = decode("znvygb:vasb@ejho.ng");
		var emailElem = document.getElementById('email')
		emailElem.setAttribute("href", y);
		emailElem.setAttribute("onclick", "");
		emailElem.firstChild.nodeValue = "E-Mail-Software öffnet sich";
	};

	return (
		<div>
			<Container className="text-start pt-4">
				<h1 style={{fontFamily: 'LeagueSpartan-SemiBold', color: COLORS.main}}>Kontakt</h1>
				<p className="pt-4 mt-4">Ronald Wagner Unternehmensberatung</p>
				<p>Ing. Mag. Ronald Wagner, EMBA</p>
				<p>Nordberggasse 53, A-8045 Graz</p>
				<p>+43 664 9573326</p>
				<p><a id="email" href="" onClick={() => openMailer()}>E-Mail: bitte klicken</a></p>
				<p><a href="https://www.xing.com/profile/Ronald_Wagner017948">Xing</a></p>
				<p><a href="https://www.linkedin.com/in/ronald-wagner-338b74273">LinkedIn</a></p>
			</Container>

		</div>
		);
	};
	
export default Contact;