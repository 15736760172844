import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { COLORS } from '../constants'
import { Link } from 'react-router-dom';
import GridElement from './GridElement';

function MABeratung() {
	return (
		<div>
			<Container className="text-start pt-4">
				<h1 style={{fontFamily: 'LeagueSpartan-SemiBold', color: COLORS.main}}>Mergers & Acquisitions-Beratung</h1>
			</Container>
			<Container className="text-start pt-4 mt-4">            
				<p>Der Bundesverband Deutscher Unternehmensberatungen (BDU) definiert M&A-Beratung folgendermaßen: </p>

				<Container className="text-start p-indent">
					<p>Die Buchstaben M&A stehen in diesem Fall für „Mergers“ und „Acquisitions“. 
						Mergers sind Unternehmensfusionen; Acquisitions beschreiben Unternehmensverkäufe. 
						M&A-Beratung ist somit ein Sammelbegriff für Transaktionen im Unternehmensbereich und umfasst sowohl den 
						Prozess als auch das Ergebnis eines Kaufs oder Zusammenschlusses von Unternehmen oder Teilen eines Unternehmens 
						inklusive der Integration in das eigene Unternehmen. Die Tätigkeit eines Beraters in dem Feld ist sehr weit gefasst, 
						denn es sind im Laufe des Verfahrens Buy-outs, Übernahmen, Fusionen, Spin-offs, Carve-outs oder auch Kooperationen möglich.</p>
				</Container>
				<p>Die M&A-Beratung ist wesentlicher Bestandteil der Beratung im Bereich der strategischen Unternehmensführung. Hierunter fallen Unternehmensübernahmen, 
					Management Buy-outs (MBO), Kooperationen & Joint Ventures sowie die Post Merger Integration. Teilbereiche von M&A fallen auch in die 
					betriebswirtschaftliche Beratung, das gilt für die Themen Unternehmensanalyse, Unternehmensbewertung und Due Diligence.</p>
				<p>Dies macht verständlich, dass die M&A-Beratung ein hochkomplexer Bereich ist und viel Know-how, Spezialisierung, Professionalität und 
					Erfahrung benötigt. Seit meinem ersten M&A-Projekt im Jahr 2003 habe ich mich über die Jahre mehr und mehr auf M&A spezialisiert. 
					Ich decke den gesamten M&A-Zyklus ab, beginnend mit der Transaktionsvorbereitung über die Strukturierung, die Vertragsverhandlungen 
					und das begleitende Management eines Deals bis zur Umsetzung und der Post-Merger-Integration. Mit meiner Erfahrung begleite ich meine 
					Kunden umfassend und erfolgreich durch ihre M&A-Transaktion.</p>
			</Container>
			{<GridElement showFirstRow={false}/>}
		</div>
		);
}

export default MABeratung;
