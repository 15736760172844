import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { COLORS, HEADLINEPROPS } from '../constants'
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

function PostMergerIntegration() {
	const [isVisible, setIsVisible] = useState(false)
    const [linkText, setLinkText] = useState("Lesen Sie weiter")
	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

	useEffect(() => {
		function handleResize() {
		  setIsSmallScreen(window.innerWidth <= 768);
		}
	
		window.addEventListener('resize', handleResize);
	
		return () => {
		  window.removeEventListener('resize', handleResize);
		};
	  }, []);
	
	let xsValue = HEADLINEPROPS.xsValue
	let colMaxWidth = HEADLINEPROPS.colMaxWidth

	if (isSmallScreen) {
		colMaxWidth = 1000
	} 

	let columnStyle = {maxWidth: String(colMaxWidth) + "rem"}


    useEffect(() => {
        if (isVisible) {
            setLinkText("Weniger anzeigen")
        } else {
            setLinkText("Lesen Sie weiter")
        }
    }, [isVisible]);
    
    const handleClick = () => {
        setIsVisible(!isVisible);
    };

	return (
		<div>
			<Container className="text-start pt-4">
				<Row>
					<Col xs={xsValue} style={columnStyle} className="d-flex">
						<Image src={require("../Images/integration.jpg")} fluid className="rounded-image" style={{alignSelf: "flex-end"}}/>
					</Col>
					<Col className="text-start" xs={12 - xsValue} style={{alignSelf: "flex-end"}}>
						<h1 style={{fontFamily: 'LeagueSpartan-SemiBold', color: COLORS.main}}>Post Merger Integration</h1>
						<h5 style={{fontFamily: 'LeagueSpartan-Medium'}}>Von Ronald Wagner</h5>
					</Col>
				</Row>
			</Container>
			<Container className="text-start pt-4 mt-4">            
				<p>Bei Mergers & Acquisitions hat die Post-Merger-Phase die weitaus größte Bedeutung für das erfolgreiche Gelingen der Transaktion. 
					Viele Zusammenschlüsse erzielen nicht die erhofften Synergien oder scheitern sogar. Nach einer deutschen Studie haben nur 38% der 
					Unternehmenszusammenschlüsse einen positiven Erfolgsbeitrag. Andererseits erziehen die Top Performer gegenüber den Durchschnittsunternehmen 
					um 90% mehr Synergien durch Integration Excellence (Gerds/Schewe 2009).</p>		

					<h3 style={{fontFamily: 'LeagueSpartan-SemiBold'}} className="pt-4">{"<"}<Link onClick={handleClick}>{linkText}</Link>{">"}</h3>
			</Container>

			{isVisible && 
                <Container className="text-start pt-4">
					<h3 style={{fontFamily: 'LeagueSpartan-Medium'}} className="pt-4">Die Integration planen</h3>

					<p>Leider haben viele mittelständische Käufer bis zur Unterzeichnung eines Kaufvertrags mehr diffuse Vorstellungen von einer möglichen 
						Integration des Zielunternehmens als ein brauchbares Konzept. Dabei sollte man sich schon im Rahmen der <Link to="/transaktionsstrategie">Transaktionsstrategie</Link> Überlegungen über eine geplante Integration und deren Umfang machen. Ist eine Vollintegration sinnvoll, also insbesondere eine 
						Integration in den Kernbereichen Leistungserstellung, Führungsstrukturen, Geschäftsprozesse oder eher nur eine Teilintegration, 
						z.B. im Vertrieb und im administrativen Bereich?</p>
					
					<p>So früh wie möglich sind im Zuge eines Transaktionsprozesses die Integrationsziele zu definieren und eine Roadmap für die Integration zu erstellen. Die  <Link to="/duediligence">Due Diligence</Link> sollte genützt 
					werden um Informationen für die Integration zu gewinnen, welche in die Planung einfließen. Anhand der erwarteten Synergien ist abzuklären, 
						welche Schritte zu ihrer Realisierung erforderlich sind und entsprechend ist die Integrationsplanung auszurichten. </p>
					
					<p>Auch sollte im Rahmen der Due Diligence kritisch hinterfragt werden, wie realistisch die erwünschten Synergien und welche Risiken mit der geplanten Integration verbunden sind. Dementsprechend 
						ist die Integrationstiefe (Vollintegration oder Teilintegration in definierten Bereichen) festzulegen.</p>
					
					<p>Im Zuge der Integrationsplanung ist auch die Kommunikationsstrategie zu entwickeln. Es geht hier vor allem um die Kommunikation mit den Mitarbeitern, aber auch mit anderen Stakeholdern wie 
						Kunden und Lieferanten. Sobald die Transaktion fixiert ist und bekannt gegeben wird, muss zügig und klar besonders mit der Belegschaft des übernommenen Unternehmens 
						kommuniziert werden: was plant der neue Eigentümer, was bedeutet das für die Mitarbeiter, für ihre Aufgaben, ihren Arbeitsplatz und was für 
						Chancen und Entfaltungsmöglichkeiten bietet das neue Unternehmen für die Mitarbeiter. Unklarheiten, Unsicherheiten und Gerüchtebildungen 
						müssen möglichst im Ansatz durch eine klare und glaubwürdige Kommunikation vermieden werden.</p>
					
					<h3 style={{fontFamily: 'LeagueSpartan-Medium'}} className="pt-4">Durchführung der Integration</h3>

					<p>Für die Integration wird ein Integrationsprojekt aufgesetzt und ein Projektteam zusammengestellt. Das Projektteam wird interdisziplinär aus beiden Unternehmen gebildet und bringt das fachliche Verständnis für die zu integrierenden Bereiche ein. Zumindest ein Teil des Projektteams sollte Erfahrung im Change-Management haben, die anderen Mitglieder erhalten eine Basisschulung in Change-Management. Bei Bedarf wird das Projektteam durch externe Interimsmanager, Berater und Coaches unterstützt. Ein wesentlicher Erfolgsfaktor ist, dass die Mitglieder des Projektteams ausreichend für das Integrationsprojekt freigespielt werden. Denn müssen die Teammitglieder weiterhin ihre operativen Tätigkeiten in vollem Umfang wahrnehmen, ist der Erfolg der Integration massiv gefährdet. Hier gibt es in der Praxis leider häufig Schwierigkeiten.</p>
 					
					<p>Im Zuge der Umsetzung der Integration zeigen sich immer wieder offene oder auch verdeckte Widerstände bei Mitarbeitern. Diese Widerstände resultieren zum Teil aus der Verunsicherung der Belegschaft. Eine gute Kommunikationsstrategie kann hier helfen. Noch häufiger resultieren Widerstände bei Mitarbeitern weniger aus dem Nicht-Wollen als mehr aus dem Nicht-Können. Mitarbeiter sind vielfach fachlich überfordert von den Änderungen. Deshalb ist ein wesentlicher Erfolgsfaktor, die Mitarbeiter zur Integration zu befähigen. Das kann durch begleitende Schulungen, Hands-on-Workshops, Verfügbarkeit von Ansprechpartnern bei fachlichen Problemstellungen und ähnliche begleitende Maßnahmen erfolgen.</p>
					
					<p>Durch das laufende Projektcontrolling wird der Fortschritt bzw. auch ein „Nichtfortschritt“ sowie besondere Projektentwicklungen, Herausforderungen und Problemstellungen teamintern überwacht und an die Geschäftsführung reportet. Fehlentwicklungen können damit frühzeitig erkannt und korrigierende Maßnahmen ergriffen werden. </p>
					
					<p className="pb-4">Für das Gelingen einer Integration ist die volle Unterstützung der Geschäftsführung, die über die Macht und die Ressourcen im Unternehmen verfügt, unabdingbar. Dies erfordert auch ein entsprechendes Engagement der Geschäftsführung. Nur das Einsetzen eines Projektteams und das gelegentliche Halten von Sonntagsreden sind hier eindeutig zu wenig. Letztlich ist für alle Beteiligen, von der Geschäftsführung über das Projektteam bis zu den Mitarbeitern, eine Integration eine große Herausforderung. </p>
					
					<Container className="text-start pt-4 mt-4 mein-angebot-container">
                        <h3 style={{fontFamily: 'LeagueSpartan-Medium', color: COLORS.main}}>Mein Angebot</h3>
                        <p>Gerne unterstütze ich Sie bei der Konzeption, Planung und Durchführung Ihrer Post Merger Integration!</p>
                        <p>Übrigens: Zum Einstieg biete ich auch zu <Link to="/workshops">M&A-Fachthemen halbtägige Workshops</Link> zum Fixpreis an. Im Workshop „Post Merger Integration“ erhalten Sie einen soliden Überblick über das Thema Post Merger Integration, die Problemstellungen und Lösungsansätze und Best Practice-Beispiele für eine erfolgreiche Integration.</p>
						<p>Ich freue mich auf Ihre Anfrage!</p>
					</Container>
				</Container>}
		</div>
		);
}

export default PostMergerIntegration;
