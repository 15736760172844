import React from 'react';
import { useState, useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { COLORS, HEADLINEPROPS } from '../constants'
import { Link } from 'react-router-dom';

function Transaktionsstrategie() {
    const [isVisible, setIsVisible] = useState(false)
    const [linkText, setLinkText] = useState("Lesen Sie weiter")
	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

	useEffect(() => {
		function handleResize() {
		  setIsSmallScreen(window.innerWidth <= 768);
		}
	
		window.addEventListener('resize', handleResize);
	
		return () => {
		  window.removeEventListener('resize', handleResize);
		};
	}, []);
	
	let xsValue = HEADLINEPROPS.xsValue
	let colMaxWidth = HEADLINEPROPS.colMaxWidth

	if (isSmallScreen) {
		colMaxWidth = 1000
	} 

	let columnStyle = {maxWidth: String(colMaxWidth) + "rem"}

    useEffect(() => {
        if (isVisible) {
            setLinkText("Weniger anzeigen")
        } else {
            setLinkText("Lesen Sie weiter")
        }
    }, [isVisible]);
    
    const handleClick = () => {
        setIsVisible(!isVisible);
    };

    return (
        <div>
            <Container className="pt-4 pb-4">
            <Row>
				<Col xs={xsValue} style={columnStyle} className="d-flex">
                    <Image src={require("../Images/compass.jpg")} fluid className="rounded-image" style={{alignSelf: "flex-end"}}/>
                </Col>
                <Col className="text-start" xs={12 - xsValue} style={{alignSelf: "flex-end"}}>
                    <h1 style={{fontFamily: 'LeagueSpartan-SemiBold', color: COLORS.main}}>Transaktionsstrategie</h1>
                    <h5 style={{fontFamily: 'LeagueSpartan-Medium'}}>Von Ronald Wagner</h5>
                </Col>
            </Row>
            </Container>
            <Container className="text-start mt-4 pt-4">            
                <p>Leider gehen viele Verkäufer, aber auch Käufer, ohne eine wohlüberlegte Transaktionsstrategie ins Rennen. 
                    Was meist dazu führt, dass Ziele nicht erreicht werden oder eine beabsichtigte Transaktion holprig abläuft oder gar scheitert.</p>
                    
                <p>Bei der Transaktionsstrategie handelt es sich weniger um einen simplen „Projektplan“ für eine beabsichtigte Transaktion, 
                    sondern vielmehr um eine längerfristige und zielorientierte Ausrichtung in Hinblick auf eine M&A-Transaktion.</p>

                <p>Die drei wesentlichen Elemente einer Transaktionsstrategie sind die Ziele, der Plan und die Taktik.</p>
                <h3 style={{fontFamily: 'LeagueSpartan-SemiBold'}} className="pt-4">{"<"}<Link onClick={handleClick}>{linkText}</Link>{">"}</h3>
            </Container>
            {isVisible && 
                <Container className="text-start pt-4">
                    <h3 style={{fontFamily: 'LeagueSpartan-Medium'}} className="pt-4">Die Ziele</h3>
                    
                    <p>Die Entwicklung einer Transaktionsstrategie beginnt mit dem Herausarbeiten der Ziele einer beabsichtigten Transaktion. 
                        Dazu müssen die Motive und Beweggründe eruiert und basierend darauf realistische Ziele definiert werden. 
                        Auch sollten im Rahmen der Zieldefinition mögliche Alternativen erörtert werden.</p>

                    <p>Steht ein Unternehmen im Eigentum von mehreren Gesellschaftern, sollte ein Konsens der Eigentümer über die Ziele einer 
                        Transaktion herbeigeführt und dann die Ziele klar benannt werden, damit sich die Eigentümer darauf verpflichten („commiten“).
                        Nichts ist unangenehmer, als wenn es im Zuge einer Transaktion zu Meinungsverschiedenheiten unter den Eigentümern kommt, 
                        häufig deshalb, weil im Vorfeld kein ausreichendes gemeinsames Verständnis aufgebaut wurde.</p>

                    <p>Bei strategischen Investoren werden die Transaktionsziele aus den Gesamtunternehmenszielen (Unternehmensstrategie) abgeleitet, 
                        das kann beispielsweise die Erschließung eines neuen Marktes durch Zukauf von Unternehmen oder die Fokussierung auf eine 
                        Kernkompetenz und deshalb der Verkauf von Unternehmensteilen, die außerhalb der Kernkompetenz liegen, sein.</p>

                    <h3 style={{fontFamily: 'LeagueSpartan-Medium'}} className="pt-4">Plan & Taktik</h3>

                    <p>Ausgehend von den Zielen ist der konkrete Weg dorthin zu planen. Welche Schritte sind für die Transaktion erforderlich, beginnend 
                        mit den Vorarbeiten bis zur Durchführung der Transaktion? Damit ist auch darzulegen, wie man sie angehen kann und welche Ressourcen 
                        dafür erforderlich sind.</p>
                    
                    <p>Die Taktik ist im Vergleich zum Plan kurzfristig angelegt, ihr kommt bei der konkreten Umsetzung des Transaktionsvorhabens, 
                        wie beispielsweise in der Verhandlungsphase, große Bedeutung zu.</p>

                    <h3 style={{fontFamily: 'LeagueSpartan-Medium'}} className="pt-4">Ist-Analyse</h3>

                    <p>Ausgangspunkt für die strategische Transaktionsplanung ist eine Ist-Analyse. Auf der Verkäuferseite ist dabei der Zustand des Unternehmens, 
                        seine Potenziale, seine Stärken und Schwächen und seine Verkaufsfähigkeit zu untersuchen. Auf der Käuferseite werden besonders die 
                        finanziellen Möglichkeiten und die Finanzierungsfähigkeit sowie die personellen Ressourcen, besonders auch im Managementbereich, 
                        für eine Transaktion und eine nachfolgende Integration analysiert. Des Weiteren kommt der Analyse von potenziellen Synergien große Bedeutung zu.</p>

                    <h3 style={{fontFamily: 'LeagueSpartan-Medium'}} className="pt-4">Definition der konkreten Transaktionsstrategie</h3>

                    <p>Basierend auf den Erkenntnissen der Ist-Analyse wird überlegt und geplant, wie man zum Ziel gelangt. 
                        Gegenstand dieser Überlegungen sind im Wesentlichen folgende Themen:</p>

                    <ul>
                        <li>Beseitigung von Schwächen eines zu verkaufenden Unternehmens</li>
                        <li>Abgrenzung des Kaufgegenstands, beispielsweise indem ein zu verkaufender Unternehmensteil in eine eigene Gesellschaft ausgegliedert wird</li>
                        <li>Überlegungen zur Strukturierung der Transaktion – dabei geht es um steuerliche und rechtliche Belange</li>
                        <li>Preisüberlegungen, insbesondere Höchst- bzw. Mindestpreis </li>
                        <li>Grundlegende Rahmenbedingungen, bei deren Über- bzw. Unterschreiten die Verhandlungen abgebrochen werden</li>
                        <li>Verhandlungstaktik</li>
                        <li>Kommunikationsstrategie, vor allem in Bezug auf die Mitarbeiter, aber auch in Bezug auf andere Stakeholder wie Kunden und Lieferanten oder Financiers</li>
                        <li>Überlegungen zur möglichen Integration eines übernommenen Unternehmens</li>
                        <li>Festlegung, wie die Suche nach geeigneten Targets bzw. Käufern erfolgen kann</li>
                    </ul>

                    <p>Es hat sich im Zuge der Entwicklung einer Transaktionsstrategie immer wieder gezeigt, dass der Kunde bzw. seine Mitarbeiter über weitaus bessere Kenntnisse 
                        des Markts und der Mitbewerber verfügen als Berater. „Logische“ Targets bzw. Kaufinteressenten ergeben sich häufig bei der Erarbeitung der 
                        Transaktionsstrategie, welche dann entsprechend behutsam und professionell angesprochen werden können.</p>

                    <p className="pb-4">Die Ausarbeitung einer Transaktionsstrategie ist mit Aufwand verbunden. Sich aber in ein M&A-Abenteuer zu stürzen, ohne sich zuvor ausreichend Gedanken 
                        darüber gemacht zu haben, ist fahrlässig und kann sich als sehr nachteilig erweisen. Denn eine gute Transaktionsstrategie kann über Erfolg oder 
                        Misserfolg einer M&A-Transaktion entscheiden.</p>

                    <Container className="text-start pt-4 mt-4 mein-angebot-container">
                        <h3 style={{fontFamily: 'LeagueSpartan-Medium', color: COLORS.main}}>Mein Angebot</h3>
                        <p>Mit meiner jahrzehntelangen Erfahrung in Mergers & Acquisitions entwickle ich mit Ihnen gerne Ihre Transaktionsstrategie. </p>
                        <p>Übrigens: Zum Einstieg biete ich auch zu verschiedenen <Link to={"/workshops"}>M&A-Fachthemen halbtägige Workshops</Link> zum Fixpreis an. Im Workshop 
                            „Transaktionsstrategie“ erhalten Sie die wesentlichen Grundlagen zum Verständnis und für die Entwicklung einer Transaktionsstrategie. 
                            Dabei werden nicht nur die drei Elemente der Transaktionsstrategie – die Ziele, der Plan und die Taktik – behandelt, sondern auch, 
                            wie eine konkrete Transaktionsstrategie definiert werden kann und wie die Inhalte einer Transaktionsstrategie aussehen.</p>
                        <p>Ich freue mich auf Ihre Anfrage!</p>
                    </Container>
                </Container>
            }
        </div>
    );
}

export default Transaktionsstrategie;
