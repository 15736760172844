import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { COLORS } from '../constants'
import { Link } from 'react-router-dom';

function MeinBeratungsansatz() {
return (
    <div>
        <Container className="text-start pt-4">
            <h1 style={{fontFamily: 'LeagueSpartan-SemiBold', color: COLORS.main}}>Mein Beratungsansatz</h1>
        </Container>
        <Container className="text-start pt-4 mt-4">            
            <p>Als selbstständiger Unternehmensberater berate ich mittelständische Kunden in <Link to={"/maberatung"}>Mergers & Acquisitions</Link> und in verwandten Themenfeldern wie z.B. Kapitalbeteiligungsmodellen für leitende Mitarbeiter. Ich decke mit meiner langjährigen Erfahrung sämtliche Bereiche und Themen über den gesamten M&A-Zyklus einer Transaktion, von der Definition der Transaktionsstrategie bis zum Closing und der Umsetzung/Integration, ab.</p>
            <p>Mein Ziel ist es, den Kunden auf den Punkt genau zu beraten, zu begleiten und mit meinem Know-how und meiner Expertise zur Seite zu stehen. Was der Kunde und seine Mitarbeiter selbst machen können, machen sie selbst – das spart Kosten. Im Einzelfall nehme ich auf Anfrage auch Interimsmanagement-Aufgaben wahr.</p>
            <p>Bei meiner Tätigkeit beachte ich die <a href={require("../Images/berufsgrundsaetze-standesregeln-unternehmensberatung.pdf")}>Berufsgrundsätze und Standesregeln in der Unternehmensberatung</a> des Fachverbands Unternehmensberatung, Buchhaltung und Informationstechnologie (UBIT). Im Gegensatz zu vielen anderen M&A-Beratern bin ich 
				Fachberater und kein Makler. Ich werde daher ausschließlich auf Time & Material-Basis und nicht auf Erfolgshonorar/Provisions-Basis tätig. 
				Bei mir bestehen deshalb keine Interessenskonflikte.
            </p>
            <p>Ich vermittle nicht Käufer (bzw. Verkäufer) an meine Kunden, weil ich kein Makler bin. Sehr wohl übernehme ich die Ansprache von potenziellen Interessenten für meine Kunden. Es hat sich im Zuge der Entwicklung einer <Link to={"/transaktionsstrategie"}>Transaktionsstrategie</Link> immer wieder gezeigt, dass der Kunde bzw. seine Mitarbeiter über weitaus bessere Kenntnisse des Markts und der Mitbewerber verfügen als Berater. „Logische“ Targets bzw. Kaufinteressenten ergeben sich häufig bei der Erarbeitung der Transaktionsstrategie, welche dann entsprechend behutsam und professionell angesprochen werden können.</p>
			<p className="link-style"><Link to="/aboutme">→ Über mich</Link></p>
		</Container>
    </div>
    );
}

export default MeinBeratungsansatz;
