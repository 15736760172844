import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { COLORS, HEADLINEPROPS } from '../constants'
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

function DueDiligence() {
	const [isVisible, setIsVisible] = useState(false)
    const [linkText, setLinkText] = useState("Lesen Sie weiter")
	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

	useEffect(() => {
		function handleResize() {
		  setIsSmallScreen(window.innerWidth <= 768);
		}
	
		window.addEventListener('resize', handleResize);
	
		return () => {
		  window.removeEventListener('resize', handleResize);
		};
	  }, []);
	
	let xsValue = HEADLINEPROPS.xsValue
	let colMaxWidth = HEADLINEPROPS.colMaxWidth

	if (isSmallScreen) {
		colMaxWidth = 1000
	} 

	let columnStyle = {maxWidth: String(colMaxWidth) + "rem"}
  
    useEffect(() => {
        if (isVisible) {
            setLinkText("Weniger anzeigen")
        } else {
            setLinkText("Lesen Sie weiter")
        }
    }, [isVisible]);
    
    const handleClick = () => {
        setIsVisible(!isVisible);
    };

	return (
		<div>
			<Container className="text-start pt-4">
				<Row>
					<Col xs={xsValue} style={columnStyle} className="d-flex">
						<Image src={require("../Images/duediligence.jpg")} fluid className="rounded-image" style={{alignSelf: "flex-end"}}/>
					</Col>
					<Col className="text-start" xs={12 - xsValue} style={{alignSelf: "flex-end"}}>
						<h1 style={{fontFamily: 'LeagueSpartan-SemiBold', color: COLORS.main}}>Due Diligence</h1>
						<h5 style={{fontFamily: 'LeagueSpartan-Medium'}}>Von Ronald Wagner</h5>
					</Col>
				</Row>
			</Container>
			<Container className="text-start pt-4 mt-4">            
				<p>Bei der Due Diligence – auch Sorgfaltsprüfung genannt – wird das Zielunternehmen geprüft und analysiert, damit sich der Käufer ein umfassendes Bild über den Zustand des Zielunternehmens machen kann. Auch hilft die Due Diligence dabei, das Geschäftsmodell des Zielunternehmens besser zu verstehen und mögliche Synergien zu bestimmen und zu bewerten. Die Due Diligence bildet damit eine wesentliche Grundlage für die Verhandlungen und die Preisfestsetzung. Nach einer deutschen Studie wird der Einfluss der Due Diligence auf den Erfolg einer Akquisition von 70% der befragten Käufer als stark oder sehr stark eingeschätzt (Knauer/Herrmann/Wagener 2017).</p>		
                <h3 style={{fontFamily: 'LeagueSpartan-SemiBold'}} className="pt-4">{"<"}<Link onClick={handleClick}>{linkText}</Link>{">"}</h3>
			</Container>

			{isVisible && 
                <Container className="text-start pt-4">
					<h3 style={{fontFamily: 'LeagueSpartan-Medium'}} className="pt-4">Themenbereiche der Due Diligence</h3>

					<p>Die Themenbereiche der Due Diligence (DD) ergeben sich aus der Geschäftstätigkeit des zu prüfenden Unternehmens. Typische Themenbereiche sind:</p>
					
					<ul>
                        <li>Financial DD (inkl. Tax): gewissermaßen der Kern einer Due Diligence. Beginnend mit den Jahresabschlüssen geht es darum, die finanzielle Lage des Zielunternehmens zu verstehen und sich ein Bild über die Vermögens-, Ertrags- und Finanzlage zu machen. Aufbauend darauf wird die Planung inkl. Prognoserechnung untersucht. Auch wird das Unternehmen einer steuerlichen Analyse unterzogen. Die Financial DD liefert die Basis für die Unternehmensbewertung und damit für die Kaufpreisverhandlungen.</li>
                        <li>Legal DD: Es werden die gesellschaftsrechtlichen Strukturen durchleuchtet, die Kunden- und Lieferantenverträge geprüft, weiters Miet- und Leasingverträge und sonstige wesentliche Verträge, aber auch z.B. Bewilligungen und Genehmigungen wie aus dem Bereich Betriebsanlagenrecht.</li>
                        <li>Human Resources DD: Dieses Thema gewinnt immer mehr an Bedeutung, in Technologiebranchen, besonders in der IT, wohl eines der wichtigsten. Wesentliche Inhalte der HR-DD sind: Qualifikation der Mitarbeiter, Gehaltsstruktur und Gehaltssystem inkl. Incentives, Arbeitnehmervertretung und Betriebsvereinbarungen, Altersstruktur der Mitarbeiter, Unternehmenskultur und Personalentwicklung in der Zielgesellschaft.</li>
                        <li>Commercial und Marketing DD: Beginnend mit der Analyse der Branche wird die Position des Zielunternehmens am Markt untersucht, die Absatz- und Preispolitik analysiert sowie die Kundenbeziehungen, Kommunikation & Marketing, Kundenstrukturen und Kundenverträge, Patente & Markenrechte.</li>
                        <li>IT-DD: Inhalte sind: IT-Infrastruktur, Datensicherheit, Datenschutz, korrekte Lizenzierung verwendeter Software usw.</li>
                        <li>Je nach Bedarf sind noch weitere Themenbereiche in der Due Diligence relevant, z.B. Compliance-DD, Technical DD inkl. Forschung & Entwicklung, Enviromental-DD.</li>
                    </ul>

					<h3 style={{fontFamily: 'LeagueSpartan-Medium'}} className="pt-4">Funktionen der Due Diligence</h3>

					<p>Eine wesentliche Funktion der Due Diligence ist es, Sicherheit zu gewinnen und das Zielunternehmen transparenter für den Käufer zu machen, denn wer will schon die Katze im Sack kaufen. Mit der Due Diligence kann ein gutes Verständnis für das Zielunternehmen beim Käufer aufgebaut werden. Wichtig ist es diesbezüglich, auch die Ziele des Käufers (vgl. <Link to="/transaktionsstrategie">Transaktionsstrategie</Link>), z.B. eine beabsichtigte Integration in das Unternehmen des Käufers, bereits in der DD mitzuberücksichtigen. Damit können wesentliche Erkenntnisse für die Post Merger Integration gewonnen werden und in die Planung der Integration einfließen.</p>

					<p>Die zweite wesentliche Funktion der DD besteht darin, fundierte Erkenntnisse hinsichtlich der Bewertung des Zielunternehmens zu erlangen. Insbesondere aufgrund der Erkenntnisse der Financial- und Commercial-Due Diligence wird der Käufer eine genauere und zuverlässigere Unternehmensbewertung vornehmen können. Nicht selten führen in der Due Diligence erkannte Risiken zu Preisabschlägen oder es werden Absicherungen für Risiken mittels Garantien oder Freistellungen verlangt.</p>

					<p>Schließlich dient die Durchführung einer Due Diligence auch der Exkulpation von Entscheidungsträgern. Denn die Nichtdurchführung einer Due Diligence bei einer Unternehmenstransaktion ist fahrlässig und kann zu einer Haftung der verantwortlichen Entscheidungsträger gegenüber dem Unternehmen bzw. den Gesellschaftern und Investoren führen.</p>
					
					<h3 style={{fontFamily: 'LeagueSpartan-Medium'}} className="pt-4">Praxis der Due Diligence</h3>

					<p>Vor Beginn der Due Diligence übermittelt der Käufer eine Request Liste an den Verkäufer, in der er die Themen, Unterlagen und Dokumente anführt, in die er Einsicht wünscht. Der Käufer stellt auch ein DD-Team zusammen, zumeist mit eigenen Mitarbeitern, aber auch bei Bedarf mit externen Experten wie Wirtschaftsprüfern, Rechtsanwälten, Unternehmensberatern oder technischen Experten.</p>
					
					<p>Die Informationen werden vom Verkäufer in einem sogenannten Datenraum zur Verfügung gestellt. Im mittelständischen Bereich läuft das heutzutage in der Praxis meistens so ab, dass nach Unterzeichnung einer Geheimhaltungsvereinbarung die Daten auf einem verschlüsselten Online-Laufwerk oder via MS SharePoint oder ähnlichen Tools zur Verfügung gestellt werden und das DD-Team mit der Prüfung beginnen kann. Leider ist es gerade hier manchmal so, dass der Verkäufer seine Hausaufgaben nicht gemacht hat und die gewünschten Daten nicht oder nur verzögert oder in einer schlechten Qualität geliefert werden. Das führt zu Datennachforderungen und Verzögerungen, was für das Vertrauen in das Zielunternehmen und den Deal nicht förderlich ist. Das kann sogar zu einer echten Belastung für die Verhandlungen werden und im Extremfall zu einem Abbruch führen.  </p>
					
					<p>Im Zuge der Due Diligence sollte auch eine Betriebsbesichtigung gemacht und vor allem Management-Gespräche (Gespräche mit Geschäftsführern und Bereichsleitern) geführt werden. Diese Gespräche sind unabdingbar, um ein gutes Verständnis für das Zielunternehmen aufzubauen.</p>
					
					<p className="pb-4">Die Ergebnisse (Findings) der Due Diligence werden käuferseitig dokumentiert und besprochen, wichtig ist dabei eine laufende interne Abstimmung innerhalb des DD-Teams und eine effektive Ergebnisauswertung und Kommunikation an die Beteiligten (Management, Verhandlungsteam). Wenn externe Berater an einer Due Diligence beteiligt sind, kommt eine sog. Full Scope Due Diligence mit einem oft Hunderte Seiten langen DD-Bericht heutzutage nur mehr selten vor. Meistens wird ein Limited Review durchgeführt und die Ergebnisse werden in einem sogenannten Red Flag Report (ein ergebnisorientierter Report in tabellarischer Form) zusammengefasst.</p>
					<Container className="text-start pt-4 mt-4 mein-angebot-container">
                        <h3 style={{fontFamily: 'LeagueSpartan-Medium', color: COLORS.main}}>Mein Angebot</h3>
                        <p>Gerne unterstütze ich Sie bei der Planung, Organisation und Durchführung einer Due Diligence.</p>
                        <p>Übrigens: Zum Einstieg biete ich auch zu verschiedenen <Link to="/workshops">M&A-Fachthemen halbtägige Workshops</Link> zum Fixpreis an. Im Workshop „Due Diligence“ erhalten Sie die wesentlichen Grundlagen zum Verständnis und zum Ablauf einer Due Diligence. Verkäufer können sich damit optimal vorbereiten und die erforderlichen Unterlagen im Vorfeld zeitgerecht aufbereiten. Käufer und ihre teilnehmenden Mitarbeiter können sich vorbereiten, um sich in der Due Diligence richtig zu verhalten und dort die relevanten Schwerpunkte zu setzen. Die Due Diligence kann damit effektiv genutzt werden, um das Zielunternehmen zu verstehen und die Erkenntnisse im weiteren Verkaufsprozess und besonders in den Preisverhandlungen zu verwerten</p>
						<p>Ich freue mich auf Ihre Anfrage!</p>
					</Container>
				</Container>}
		</div>
		);
}

export default DueDiligence;
