import React from 'react';
import { Container } from 'react-bootstrap';

function Footer() {
	
	return(
		<div className="footer">
			<footer className="page-footer font-small blue pt-4">
				<div className="container-fluid text-center text-md-left">
					<Container className="text-start">
						<div className="row">
							{/*<div className="col-md-2 mt-md-0 mt-3">
								<h5 className="text-uppercase">Footer Content</h5>
								<p>Here you can use rows and columns to organize your footer content.</p>
							</div>

							<hr className="clearfix w-100 d-md-none pb-0"/>*/}

							<div className="col-md-6 mb-md-0 mb-3 ps-0">
								<ul className="list-unstyled">
									<li><a href="/impressum" className="footer-link">Impressum</a></li>
									<li><a href={require("../Images/unternehmensberatung-agb.pdf")} className="footer-link">AGB</a></li>
									<li><a href={require("../Images/berufsgrundsaetze-standesregeln-unternehmensberatung.pdf")} className="footer-link">Berufsgrundsätze und Standesregeln</a></li>
								</ul>
							</div>
					
							<div className="col-md-6 mb-md-0 mb-3 ps-0">
								<ul className="list-unstyled">
									<li><a href="/contact" className="footer-link">Kontakt</a></li>
									<li><a href="/aboutme" className="footer-link">Über mich</a></li>
									<li><a href="/beratungsansatz" className="footer-link">Mein Beratungsansatz</a></li>
								</ul>
							</div>
						</div>
					</Container>
				</div>

				<div className="footer-copyright text-center py-3">© 2023 Copyright: Ronald Wagner </div>
			</footer>
		</div>
	);
}

export default Footer