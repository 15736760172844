import React from 'react';
import { Container } from 'react-bootstrap';
import { COLORS } from '../constants'

function Impressum() {
	function decode(a) {
		return a.replace(/[a-zA-Z]/g, function(c) {
			return String.fromCharCode((c <= "Z" ? 90 : 122) >= (c = c.charCodeAt(0) + 13) ? c : c - 26);
		})
	}

	function openMailer() {
		var y = decode("znvygb:vasb@ejho.ng");
		var emailElem = document.getElementById('email')
		emailElem.setAttribute("href", y);
		emailElem.setAttribute("onclick", "");
		emailElem.firstChild.nodeValue = "E-Mail-Software öffnet sich";
	};

	return (
		<div>
			<Container className="text-start pt-4">
				<h1 style={{fontFamily: 'LeagueSpartan-SemiBold', color: COLORS.main}}>Impressum und Offenlegung</h1>
				<p className="pt-4 mt-4">Diese Website enthält Information über Produkte und Dienstleistungen des Unternehmens sowie Förderung des Absatzes derselben. </p>
				<p>Medieninhaber: Ing. Mag. Ronald Wagner</p>
				<p>Nordberggasse 53, A-8045 Graz</p>
				<p>Tel. +43 664 9573326</p>
				<p><a id="email" href="" onClick={() => openMailer()}>E-Mail: bitte klicken</a></p>
				<p>UID: ATU59720779</p>
				<p>Rechtsform: Einzelunternehmen</p>
				<p>Unternehmensgegenstand: Unternehmensberatung einschließlich der Unternehmensorganisation gemäß § 94 Z. 74 GewO 1994</p>
				<p>Mitglied der Wirtschaftskammer Steiermark, Österreich, Fachverband Unternehmensberatung, Buchhaltung und Informationstechnologie (UBIT)</p>
				<p>Aufsichtsbehörde: Magistrat der Stadt Graz</p>
				<p>Anwendbare gewerbe- oder berufsrechtliche Vorschriften: Gewerbeordnung 1994, <a href="https://www.ris.bka.gv.at/Bundesrecht/">https://www.ris.bka.gv.at/Bundesrecht/</a></p>
			</Container>
		</div>
		);
	};
	
export default Impressum;