import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { COLORS } from '../constants'
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import GridElement from './GridElement';


function LandingPage() {
	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);
	const [headlineSize, setHeadlineSize] = useState("3rem");


	useEffect(() => {
		function handleResize() {
		  setIsSmallScreen(window.innerWidth <= 768);
		  if (window.innerWidth <= 768) {
			setHeadlineSize("3rem")
		  } else {
			setHeadlineSize("4rem")
		  }
		}
	
		window.addEventListener('resize', handleResize);
	
		return () => {
		  window.removeEventListener('resize', handleResize);
		};
	  }, []);

	return (
		<div>
			<Container className="text-start pt-4">
				<h1 style={{fontFamily: 'LeagueSpartan-SemiBold', color: COLORS.main, fontSize: headlineSize}}>Mergers & Acquisitions</h1>
				{/*<h1 style={{fontFamily: 'LeagueSpartan-SemiBold', color: COLORS.dark}}>Unternehmensberatung</h1>*/}
			</Container>
			<Container className="text-start pt-4 mt-4">
				<p>Mergers & Acquisitions sind wesentlicher Bestandteil der strategischen Unternehmensführung. Hierunter fallen Unternehmensübernahmen, 
					Management Buy-outs (MBO), Kooperationen & Joint Ventures sowie die Post Merger Integration. Bei M&A ist Kompetenz gefragt und 
					daher sollte Sie die M&A Beratung auch nicht irgendwem überlassen.</p>
			</Container>
			{<GridElement showFirstRow={true}/>}
		</div>
	);
}

export default LandingPage;
