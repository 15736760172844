import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { COLORS } from '../constants'
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

function AboutMe() {
	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

	useEffect(() => {
		function handleResize() {
		  setIsSmallScreen(window.innerWidth <= 768);
		}
	
		window.addEventListener('resize', handleResize);
	
		return () => {
		  window.removeEventListener('resize', handleResize);
		};
	  }, []);
	
	let xsValue = 5 //isSmallScreen ? 4 : 2;
	let colMaxWidth = 13

	if (isSmallScreen) {
		colMaxWidth = 15
	} 

	let columnStyle = {maxWidth: String(colMaxWidth) + "rem"}

    return (
        <div>
            <Container className="pt-4 pb-4">
            <Row>
                <Col xs={xsValue} style={columnStyle} className="d-flex">
                    <Image src={require("../Images/me_2.jpg")} fluid style={{alignSelf: "flex-end"}}/>
                </Col>
                <Col className="text-start" xs={12 - xsValue} style={{alignSelf: "flex-end"}}>
                    <h1 style={{fontFamily: 'LeagueSpartan-SemiBold', color: COLORS.main}}>Über mich – mein Beraterprofil</h1>
                    <h5 style={{fontFamily: 'LeagueSpartan-Medium'}}>Ing. Mag. Ronald Wagner, EMBA (M&A, WWU Münster)</h5>
                    <h5>Senior Consultant, Experte für Mergers & Acquisitions</h5>
                </Col>
            </Row>
            </Container>
            <Container className="text-start pt-4 mt-4">
                <h3 style={{fontFamily: 'LeagueSpartan-Medium'}}>Statement</h3>
                
                <p>Seit meinem ersten M&A-Projekt im Jahr 2003 habe ich mich über die Jahre mehr und mehr auf M&A spezialisiert. Mit meiner Erfahrung begleite ich meine Kunden umfassend und erfolgreich durch ihre M&A-Transaktion. Ich decke den gesamten M&A-Zyklus ab, beginnend von der Transaktionsvorbereitung über die Strukturierung, die Vertragsverhandlungen und das begleitende Management eines Deals bis zur Umsetzung und der Post-Merger-Integration. </p>
                <p>Aufgrund meiner ursprünglich technischen Ausbildung, meiner Erfahrung in der Industrie ebenso wie als kaufmännischer Leiter eines mittelständischen IT-Unternehmens verstehe ich die Anforderungen und den „Need“ von Unternehmern und Entrepreneuren. </p>
                
                <h3 style={{fontFamily: 'LeagueSpartan-Medium'}} className="pt-4">Kompetenzen</h3>

                <ul>
                    <li>Wirtschaftliches Know-how, Schwerpunkt Corporate Finance und Unternehmensbewertung</li>
                    <li>Wirtschaftsrecht, Schwerpunkt M&A</li>
                    <li>Mergers & Acquisitions Expertise über den gesamten Lauf einer Transaktion</li>
                    <li>Systemische Unternehmensberatung nach CMC/incite</li>
                    <li>Prozessmanagement und Geschäftsprozessoptimierung (bis 2019: SPcM nach GP)</li>
                </ul>

                <h3 style={{fontFamily: 'LeagueSpartan-Medium'}}>Branchenerfahrung</h3>

                <ul>
                    <li>Bauwirtschaft (6 Jahre)</li>
                    <li>Automotive (3 Jahre)</li>
                    <li>Informationstechnologie (4 Jahre)</li>
                    <li>Unternehmensberatung (seit 2009)</li>
                </ul>

                <h3 style={{fontFamily: 'LeagueSpartan-Medium'}}>Regionen</h3>
                <ul>
                    <li>Österreich</li>
                    <li>Deutschland (Bayern, Baden-Württemberg)</li>
                </ul>

                <p className="link-style"><Link to="/beratungsansatz">→ Mein Beratungsansatz</Link></p>
            </Container>
        </div>
        );
}

export default AboutMe;
