import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';


function GridElement(props) {
	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);
	useEffect(() => {
		function handleResize() {
		  setIsSmallScreen(window.innerWidth <= 768);
		}
	
		window.addEventListener('resize', handleResize);
	
		return () => {
		  window.removeEventListener('resize', handleResize);
		};
	  }, []);
	
	let xsValue = 4
	let colMaxWidth = 20

	if (isSmallScreen) {
		colMaxWidth = 1000
	} 

	let columStyle = {maxWidth: String(colMaxWidth) + "rem", paddingLeft: "4rem"}

	if (isSmallScreen) {
		columStyle = {maxWidth: String(colMaxWidth) + "vw", paddingLeft: "2rem"}
	}


	let bigScreenContent = <div>
		{props.showFirstRow &&
		<Row className="d-flex align-items-baseline justify-content-center align-items-center">
			<Col xs={xsValue} style={columStyle}>
				<Button href="/maberatung" className="button-shadow image-button">
					<div className="image-container">
						<Image src={require("../Images/ma_beratung.png")} fluid className="button-image"/>
						<div className="overlay">
						<p className="overlay-text"></p>
						</div>
					</div>
				</Button>
				<h4 style={{fontFamily: 'LeagueSpartan-Medium'}} className="mt-3 pt-3">Mergers & Acquisitions Beratung</h4>
			</Col>
			<Col xs={xsValue} style={columStyle}>
				<Button href="/workshops" className="button-shadow image-button">
					<div className="image-container">
						<Image src={require("../Images/ma_workshop.png")} fluid className="button-image"/>
						<div className="overlay">
						<p className="overlay-text"></p>
						</div>
					</div>
				</Button>
				<h4 style={{fontFamily: 'LeagueSpartan-Medium'}} className="mt-3 pt-3">Mergers & Acquisitions Workshops</h4>
			</Col>
			<Col xs={xsValue} style={columStyle}></Col>
		</Row>}

		<Row className="d-flex align-items-baseline justify-content-center align-items-centers mt-4 pt-4">
			<Col xs={xsValue} style={columStyle}>
				<Button href="/verkaufsprozess" className="button-shadow image-button">
					<div className="image-container">
						<Image src={require("../Images/prozesse.png")} fluid className="button-image"/>
						<div className="overlay">
						<p className="overlay-text"></p>
						</div>
					</div>
				</Button>
				<h4 style={{fontFamily: 'LeagueSpartan-Medium'}} className="mt-3 pt-3">Der Verkaufsprozess</h4>
			</Col>
			<Col xs={xsValue} style={columStyle}>
				<Button href="/transaktionsstrategie" className="button-shadow image-button">
					<div className="image-container image-button">
						<Image src={require("../Images/compass.jpg")} fluid className="button-image"/>
						<div className="overlay">
						<p className="overlay-text"></p>
						</div>
					</div>
				</Button>
				<h4 style={{fontFamily: 'LeagueSpartan-Medium'}} className="mt-3 pt-3">Transaktionsstrategie</h4>
			</Col>
			<Col xs={xsValue} style={columStyle}>
				<Button href="/unternehmensbewertung" className="button-shadow image-button">
					<div className="image-container">
						<Image src={require("../Images/money.jpg")} fluid className="button-image"/>
						<div className="overlay">
						<p className="overlay-text"></p>
						</div>
					</div>
				</Button>
				<h4 style={{fontFamily: 'LeagueSpartan-Medium'}} className="mt-3 pt-3">Unternehmens&shy;bewertung & Kaufpreis&shy;ermittlung</h4>
			</Col>
		</Row>

		<Row className="d-flex align-items-baseline justify-content-center align-items-centers mt-4 pt-4">
			<Col xs={xsValue} style={columStyle}>
				<Button href="/duediligence" className="button-shadow image-button">
					<div className="image-container">
						<Image src={require("../Images/duediligence.jpg")} fluid className="button-image"/>
						<div className="overlay">
						<p className="overlay-text"></p>
						</div>
					</div>
				</Button>
				<h4 style={{fontFamily: 'LeagueSpartan-Medium'}} className="mt-3 pt-3">Due Diligence</h4>
			</Col>
			<Col xs={xsValue} style={columStyle}>
				<Button href="/unternehmenskaufvertrag" className="button-shadow">
					<div className="image-container image-button">
						<Image src={require("../Images/paragraph.png")} fluid className="button-image"/>
						<div className="overlay">
						<p className="overlay-text"></p>
						</div>
					</div>
				</Button>
				<h4 style={{fontFamily: 'LeagueSpartan-Medium'}} className="mt-3 pt-3">Unternehmenskauf&shy;verträge & Vertrags&shy;verhandlungen</h4>
			</Col>
			<Col xs={xsValue} style={columStyle}>
				<Button href="/postmerger" className="button-shadow image-button">
					<div className="image-container">
						<Image src={require("../Images/integration.jpg")} fluid className="button-image"/>
						<div className="overlay">
						<p className="overlay-text"></p>
						</div>
					</div>
				</Button>
				<h4 style={{fontFamily: 'LeagueSpartan-Medium'}} className="mt-3 pt-3">Post Merger Integration</h4>
			</Col>
		</Row>
	</div>

	let smallScreenContent = <div>
		{props.showFirstRow &&
		<Row className="d-flex align-items-baseline justify-content-center align-items-center">
			<Col xs={6} style={columStyle}>
				<Button href="/maberatung" className="button-shadow image-button">
					<div className="image-container">
						<Image src={require("../Images/ma_beratung.png")} fluid className="button-image"/>
						<div className="overlay">
						<p className="overlay-text"></p>
						</div>
					</div>
				</Button>
				<h4 style={{fontFamily: 'LeagueSpartan-Medium'}} className="mt-3 pt-3">Mergers & Acquisitions Beratung</h4>
			</Col>
			<Col xs={6} style={columStyle}>
				<Button href="/workshops" className="button-shadow image-button">
					<div className="image-container">
						<Image src={require("../Images/ma_workshop.png")} fluid className="button-image"/>
						<div className="overlay">
						<p className="overlay-text"></p>
						</div>
					</div>
				</Button>
				<h4 style={{fontFamily: 'LeagueSpartan-Medium'}} className="mt-3 pt-3">Mergers & Acquisitions Workshops</h4>
			</Col>
		</Row>}

		<Row className="d-flex align-items-baseline justify-content-center align-items-centers mt-4 pt-4">
			<Col xs={6} style={columStyle}>
				<Button href="/verkaufsprozess" className="button-shadow image-button">
					<div className="image-container">
						<Image src={require("../Images/prozesse.png")} fluid className="button-image"/>
						<div className="overlay">
						<p className="overlay-text"></p>
						</div>
					</div>
				</Button>
				<h4 style={{fontFamily: 'LeagueSpartan-Medium'}} className="mt-3 pt-3">Der Verkaufsprozess</h4>
			</Col>
			<Col xs={6} style={columStyle}>
				<Button href="/transaktionsstrategie" className="button-shadow image-button">
					<div className="image-container image-button">
						<Image src={require("../Images/compass.jpg")} fluid className="button-image"/>
						<div className="overlay">
						<p className="overlay-text"></p>
						</div>
					</div>
				</Button>
				<h4 style={{fontFamily: 'LeagueSpartan-Medium'}} className="mt-3 pt-3">Transaktionsstrategie</h4>
			</Col>
		</Row>
		<Row className="d-flex align-items-baseline justify-content-center align-items-centers mt-4 pt-4">
			<Col xs={6} style={columStyle}>
				<Button href="/unternehmensbewertung" className="button-shadow image-button">
					<div className="image-container">
						<Image src={require("../Images/money.jpg")} fluid className="button-image"/>
						<div className="overlay">
						<p className="overlay-text"></p>
						</div>
					</div>
				</Button>
				<h4 style={{fontFamily: 'LeagueSpartan-Medium'}} className="mt-3 pt-3">Unternehmens&shy;bewertung & Kaufpreis&shy;ermittlung</h4>
			</Col>
			<Col xs={6} style={columStyle}>
				<Button href="/duediligence" className="button-shadow image-button">
					<div className="image-container">
						<Image src={require("../Images/duediligence.jpg")} fluid className="button-image"/>
						<div className="overlay">
						<p className="overlay-text"></p>
						</div>
					</div>
				</Button>
				<h4 style={{fontFamily: 'LeagueSpartan-Medium'}} className="mt-3 pt-3">Due Diligence</h4>
			</Col>
		</Row>

		<Row className="d-flex align-items-baseline justify-content-center align-items-centers mt-4 pt-4">
			<Col xs={6} style={columStyle}>
				<Button href="/unternehmenskaufvertrag" className="button-shadow">
					<div className="image-container image-button">
						<Image src={require("../Images/paragraph.png")} fluid className="button-image"/>
						<div className="overlay">
						<p className="overlay-text"></p>
						</div>
					</div>
				</Button>
				<h4 style={{fontFamily: 'LeagueSpartan-Medium'}} className="mt-3 pt-3">Unternehmenskauf&shy;verträge & Vertrags&shy;verhandlungen</h4>
			</Col>
			<Col xs={6} style={columStyle}>
				<Button href="/postmerger" className="button-shadow image-button">
					<div className="image-container">
						<Image src={require("../Images/integration.jpg")} fluid className="button-image"/>
						<div className="overlay">
						<p className="overlay-text"></p>
						</div>
					</div>
				</Button>
				<h4 style={{fontFamily: 'LeagueSpartan-Medium'}} className="mt-3 pt-3">Post Merger Integration</h4>
			</Col>
		</Row>
	</div>

	return (
		<div>
			<Container className="text-start pt-4 mt-4 ps-0">            
				{isSmallScreen ? (smallScreenContent) : (bigScreenContent)}
			</Container>
		</div>
	);
}

export default GridElement;
