import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { COLORS, HEADLINEPROPS } from '../constants'
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

function Unternehmenskaufvertrag() {
	const [isVisible, setIsVisible] = useState(false)
    const [linkText, setLinkText] = useState("Lesen Sie weiter")
	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

	useEffect(() => {
		function handleResize() {
		  setIsSmallScreen(window.innerWidth <= 768);
		}
	
		window.addEventListener('resize', handleResize);
	
		return () => {
		  window.removeEventListener('resize', handleResize);
		};
	  }, []);
	
	let xsValue = HEADLINEPROPS.xsValue
	let colMaxWidth = HEADLINEPROPS.colMaxWidth

	if (isSmallScreen) {
		colMaxWidth = 1000
	} 

	let columnStyle = {maxWidth: String(colMaxWidth) + "rem"}


    useEffect(() => {
        if (isVisible) {
            setLinkText("Weniger anzeigen")
        } else {
            setLinkText("Lesen Sie weiter")
        }
    }, [isVisible]);
    
    const handleClick = () => {
        setIsVisible(!isVisible);
    };

	return (
		<div>
			<Container className="text-start pt-4">
				<Row>
					<Col xs={xsValue} style={columnStyle} className="d-flex">
						<Image src={require("../Images/paragraph.jpg")} fluid className="rounded-image" style={{alignSelf: "flex-end"}}/>
					</Col>
					<Col className="text-start" xs={12 - xsValue} style={{alignSelf: "flex-end"}}>
						<h1 style={{fontFamily: 'LeagueSpartan-SemiBold', color: COLORS.main}}>Unternehmenskaufverträge und Vertragsverhandlungen</h1>
						<h5 style={{fontFamily: 'LeagueSpartan-Medium'}}>Von Ronald Wagner</h5>
					</Col>
				</Row>
			</Container>
			<Container className="text-start pt-4 mt-4">            
				<p>Unternehmenskaufverträge sind schon alleine vom Umfang her komplexe rechtlich-wirtschaftliche Gebilde, deren Gestaltung und Verhandlung entsprechende Expertise benötigt.</p>
				
				<h3 style={{fontFamily: 'LeagueSpartan-Medium'}} className="pt-4">Strukturierung der Transaktion</h3>

				<p>Unter Strukturierung der Transaktion versteht man einerseits die Ausgestaltung des Transaktionsprozesses selbst, also wie dieser abläuft, 
					welche Schritte, Meilensteine usw. vorgesehen sind (siehe: <Link to="/verkaufsprozess">Verkaufsprozess</Link>).</p>
				
				<p>Andererseits ist jede M&A-Transaktion in einer rechtlichen Struktur abzubilden, wobei angestrebt wird, die für die Vertragspartner optimale Struktur zu finden.</p>
                <h3 style={{fontFamily: 'LeagueSpartan-SemiBold'}} className="pt-4">{"<"}<Link onClick={handleClick}>{linkText}</Link>{">"}</h3>
			</Container>

			{isVisible && 
                <Container className="text-start pt-4">
					<p>Die rechtliche Struktur wird hauptsächlich geprägt von steuerlichen Optimierungen und ggf. auch von Anforderungen einer käuferseitigen Fremdfinanzierung. Aber auch besondere Anforderungen im Einzelfall, beispielsweise in Zusammenhang mit Mezzanin-Kapital, oder einfach pragmatische Überlegungen können ebenso die Struktur einer Transaktion beeinflussen.</p>
 					
					<ul className="ps-4 pt-4">
                        <li>Asset Deal oder Share Deal</li>
                    </ul>

					<p>Bei einem Asset Deal werden die einzelnen Wirtschaftsgüter („Assets“), wie Betriebsanlagen, Grundstücke, Kundenstamm und Kundenverträge usw., einzeln verkauft und übertragen. Damit kann man festlegen, welche Teile eines Unternehmens übertragen werden und welche ggf. nicht. Das macht den Asset Deal beispielsweise interessant, wenn Unternehmensteile aus einer Insolvenzmasse heraus verkauft oder nur ein Teilbetrieb verkauft werden soll.</p>
					
					<p>Der Standardfall bei M&A-Transaktionen ist der Share Deal. Dabei werden typischerweise die Anteile an einer GmbH verkauft. Die GmbH selbst mit allen ihren Wirtschaftsgütern und Rechtsverhältnissen bleibt durch den Verkauf unberührt, die Veränderung findet nur auf der Ebene der Gesellschafter statt. Der sogenannte Kauf- und Abtretungsvertrag über GmbH-Anteile wird im M&A-Jargon SPA (Share Purchase Agreement) genannt.</p>
					
					<ul className="ps-4 pt-4">
                        <li>Fremdfinanzierung der Transaktion</li>
                    </ul>
					
					<p>Wird die Transaktion vom neuen Eigentümer mit Fremdmitteln finanziert, typischerweise bei Private Equity Transaktionen, wird zumeist eine Zwischengesellschaft als Erwerbsvehikel („NewCo“) zwischen der operativen Zielgesellschaft und dem neuen Eigentümer eingezogen, die auch den Kredit für die Finanzierung aufnimmt. </p>
					
					<p>Da die NewCo Kreditnehmerin ist, fallen bei ihr auch die Kreditzinsen als Aufwand an. Um Körperschaftsteuer zu sparen, wäre es besser, man könnte diese Kosten wirtschaftlich in die Zielgesellschaft bringen (sogenannter Debt Push Down). Man kann natürlich versuchen, durch Konzernumlagen oder Lizenzgebühren Kosten in die operative Gesellschaft zu bringen, was aber steuerlich und gesellschaftsrechtlich problematisch ist. Besser lässt sich das in Österreich durch die Gruppenbesteuerung erreichen, in Deutschland kann dazu eine ertragssteuerliche Organschaft zwischen NewCo und Zielgesellschaft errichtet werden.</p>
					
					<h3 style={{fontFamily: 'LeagueSpartan-Medium'}} className="pt-4">Wesentliche Vertragsinhalte eines SPA</h3>

					<ul className="ps-4 pt-4">
                        <li>Kaufpreis und Stichtag</li>
                    </ul>

					<p>Zumeist einigen sich die Vertragspartner anhand einer Bewertungsmethode auf einen Kaufpreis Cash and Debt Free (siehe: <Link to="/unternehmensbewertung">Unternehmensbewertung/Preisfindung</Link>). Dieser Kaufpreis wird auf einen Stichtag bezogen. In den vergangenen Jahren hat sich der Locked Box-Mechanismus bei Unternehmenskäufen durchgesetzt. Das heißt, dass der Kaufpreis für einen kürzlich zurückliegenden Stichtag auf Basis eines Jahresabschlusses oder Zwischenabschlusses festgelegt wird. Dieser Stichtag ist zugleich der Stichtag des rückwirkenden wirtschaftlichen Übergangs des Zielunternehmens. Da der Käufer erst mit dem Closing die Verfügungsmacht über das Unternehmen erhält, werden No Leakage-Klauseln vereinbart, die verhindern, dass der Verkäufer zwischen dem Stichtag und dem Tag des Closings unberechtigterweise Geld oder andere Vermögenswerte aus dem Unternehmen abzieht.</p>

					<p>Unter Closing wird übrigens jener Zeitpunkt verstanden, zu dem der Deal vollzogen, also „perfekt“ wird. Das Closing tritt ein, wenn sämtliche Closing-Bedingungen (z.B. Einlangen des Kaufpreises auf dem Treuhandkonto, keine Untersagung des Deals durch die Wettbewerbsbehörde) erfüllt sind. </p>
					
					<ul className="ps-4 pt-4">
                        <li>Earn-out-Klauseln </li>
                    </ul>

					<p>Earn-out-Klauseln sind eine Möglichkeit zur Überbrückung von unterschiedlichen Kaufpreisvorstellungen zwischen den Verhandlungspartnern. Zu einem Basiskaufpreis wird ein variabler Kaufpreis hinzugeschlagen, der in Abhängigkeit eines definierten zukünftigen Ereignisses (z.B. Erreichung eines definierten Umsatzes oder EBITs zum Zeitpunkt X) zur Auszahlung gelangt.</p>
					
					<ul className="ps-4 pt-4">
                        <li>Gewährleistungen</li>
                    </ul>

					<p>Unternehmenskaufverträge enthalten einen sehr umfangreichen Katalog an Gewährleistungsbestimmungen, die oft mehr als die Hälfte des Vertragstextes ausmachen. Neben den Fundamentalgarantien für die Eigentümerschaft und Lastenfreiheit des verkauften Anteils ist die Bilanzgarantie ein wesentlicher Verhandlungspunkt. Mit der Bilanzgarantie garantiert der Verkäufer die Richtigkeit des dem Kaufvertrag zugrundeliegenden Jahres- bzw. Zwischenabschlusses und die Höhe des darin ausgewiesenen Eigenkapitals. Weitere typische Gewährleistungen betreffen materielle und immaterielle Vermögensgegenstände, Vertragsbeziehungen zu Kunden und Lieferanten, Arbeitnehmer, Umweltrisiken und Steuern & Abgaben.</p>
					
					<p>Es ist üblich, die vereinbarten Garantien der Höhe nach zu begrenzen, typischerweise mit einem Prozentsatz des Kaufpreises („Cap“). Wenn ein Schadensfall bereits bekannt, aber die Höhe des Schadens noch unklar ist (z.B. Umweltschaden/Altlasten), wird dafür häufig eine Freistellung vereinbart, d.h. der Verkäufer verpflichtet sich, bezüglich dieses Sachverhalts den Käufer zukünftig schad- und klaglos zu halten.</p>
					
					<ul className="ps-4 pt-4">
                        <li>Streitbeilegungsmechanismus</li>
                    </ul>

					<p>Das ist ein wichtiger Punkt. Auf jeden Fall sollte ein Streitbeilegungsmechanismus (z.B. Mediationsverfahren) vereinbart werden, der verpflichtend einer allfälligen Gerichtsanrufung vorauszugehen hat. So kann oft noch eine Einigung erzielt werden, obwohl die Fronten bereits verhärtet sind. </p>
					
					<p>Bei größeren Transaktionen wird eine Schiedsklausel vereinbart, d.h. dass anstelle der staatlichen Gerichte die Zuständigkeit eines Schiedsgerichts vereinbart wird, das verbindlich und endgültig über einen Rechtsstreit aus dem Unternehmenskauf zu entscheiden hat. Zwar ist so ein Schiedsverfahren ziemlich teuer, aber die Schiedsgerichte sind in Wirtschaftsangelegenheiten schneller und professioneller als staatliche Gerichte.</p>
					
					<h3 style={{fontFamily: 'LeagueSpartan-Medium'}} className="pt-4">Vertragsverhandlungen</h3>

					<p>Die Vertragsverhandlungen beginnen schon lange vor der Verhandlung des eigentlichen Vertragstextes. Der wesentliche Rahmen für den Vertrag inkl. Kaufpreis wird schon im Vorfeld in einem Dokument wie einem Letter of Intent, einem Term-Sheet, einem Non-Binding-Offer oder Ähnlichem festgelegt. Ist hier einmal ein Punkt benannt und geregelt, ist es sehr schwierig, in den nachfolgenden Vertragsverhandlungen davon wegzukommen.</p>
					
					<p>Ist nun der Rahmen mit einem LOI o.ä. abgesteckt und die Due Diligence durchgeführt oder zumindest schon im Gange, legt eine Seite, meist die Verkäuferseite, einen Vertragsentwurf vor. Dieser Vertragsentwurf wird von der Gegenseite kommentiert und ein Verhandlungstermin angesetzt. Für die Vertragsverhandlungen stellen beide Seiten ein Verhandlungsteam zusammen. Es gibt dafür keine festen Regeln, teilweise werden die Vertragsverhandlungen federführend von Juristen und M&A-Beratern geführt, teilweise bringen sich die Eigentümer bzw. Entscheidungsträger auch selbst stärker in die Verhandlungen ein. Jedenfalls erfordern solche Verhandlungen Geschick, Erfahrung und Professionalität. Bluffen oder Taktiken wie „Good Cop Bad Cop“ mögen sich im Kino gut ausnehmen, sind aber im M&A-Business eher weniger professionell. </p>
					
					<p className="pb-4">Zumeist sind einige Verhandlungsrunden erforderlich, bis der endgültige Vertragstext steht. In der Praxis hat sich ein kooperativer und lösungsorientierter Verhandlungsstil bewährt, mit dem man zügig einen für die Partner allseits akzeptablen Vertragsabschluss erreicht, im Gegensatz zu einem aggressiven, auf Konfrontation setzenden Verhandlungsstil, mit dem man eher nur eine Verhärtung der Fronten und möglicherweise sogar einen Abbruch erzielt, denn einen erfolgreichen Vertragsabschluss. </p>
					<Container className="text-start pt-4 mt-4 mein-angebot-container">
                        <h3 style={{fontFamily: 'LeagueSpartan-Medium', color: COLORS.main}}>Mein Angebot</h3>
                        <p>Gerne unterstütze ich Sie in allen Phasen bei der Ausarbeitung und professionellen Verhandlung Ihres Unternehmenskaufvertrages mit meinem rechtlichen und wirtschaftlichen Know-how.</p>
                        <p>Übrigens: Zum Einstieg biete ich auch zu verschiedenen <Link to="/workshops">M&A-Fachthemen halbtägige Workshops</Link> zum Fixpreis an. Im Workshop „Unternehmenskaufverträge und Vertragsverhandlungen“ erhalten Sie 
							das Basis Know-how zu Unternehmenskaufverträgen und zu den Vertragsverhandlungen sowie Einblick in den Ablauf von Verhandlungen und was es dabei zu beachten gilt.</p>
                        <p>Ich freue mich auf Ihre Anfrage!</p>
                    </Container>
				</Container>}
		</div>
		);
}

export default Unternehmenskaufvertrag;
